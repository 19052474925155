<header>
  <nav class="navbar navbar-expand-md navbar-dark bd-navbar" *ngIf="isLoggedIn()">    
    <div class="col-xs-12 col-sm-3  col-lg-3 pdl0 text-center">      
     
    </div>  
    <div class="col-xs-12  col-sm-9 col-lg-8 align-screen sin_pd_mg_left">
      <a href="https://t.me/forceofsales" target="_blank" ><i class="pi pi-telegram icon-profile hand"></i></a>      
      &nbsp;
      <i class="pi pi-bell icon-profile hand" *ngIf="isLoggedIn()" [matMenuTriggerFor]="menu_notificacion"></i>                     
      <mat-menu #menu_notificacion="matMenu" yPosition="below" xPosition ="before" class="mgt20"  [overlapTrigger]="false">        
        <button mat-menu-item >          
          <span>Sin notificaciones</span>
        </button>
      </mat-menu>   
      &nbsp;   
      <span [matMenuTriggerFor]="menu" class="hand" *ngIf="isLoggedIn()">
        <button mat-icon-button aria-label="Profile" class="bt_profile">
          <mat-icon class="icon-profile">account_circle</mat-icon>    
        </button>
        &nbsp;<span class="text_profile hand" >{{name}}
          <mat-icon  class="menu-button mat-icon material-icons mat-icon-arrow expand">expand_more</mat-icon>
      </span>
      
      <mat-menu #menu="matMenu" yPosition="below" xPosition ="after" class="mgt20 menu_system"  [overlapTrigger]="false">
        <mat-list>
          <mat-list-item >
            <button mat-menu-item  routerLink="{{back_link+global.ROUTE_DASHBOARD}}" *ngIf="isLoggedIn()">
              <mat-icon>dashboard</mat-icon>
              <span class="option_menu">{{constants.LABEL_GO_TO_PANEL}}</span>
            </button>
          </mat-list-item>
          <mat-list-item >
            <button mat-menu-item routerLink="{{back_link_perfil}}">
              <mat-icon>person</mat-icon>
              <span class="option_menu">Perfil</span>
            </button>
          </mat-list-item>

          <mat-list-item *ngIf="is_investor">
            <button mat-menu-item  [matMenuTriggerFor]="met" >
              <mat-icon>insert_link</mat-icon>
              <span class="option_menu">Link de invitación</span>
            </button>
            <mat-menu #met="matMenu" class="link">
              <div style="padding:10px;">
                <ng-container >      
                  <button mat-menu-item class="nml" (click)="copyToClipboard();">
                    &nbsp;<span class="option_menu"> <mat-icon>file_copy</mat-icon> Copiar</span>
                  </button>
                </ng-container>
              </div>
            </mat-menu>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item >
            <button mat-menu-item (click)="closeSession()">
              <mat-icon>power_settings_new</mat-icon>
              <span class="option_menu">{{constants.LABEL_CLOSE_SESION}}</span>
            </button>
          </mat-list-item>
        </mat-list>
       </mat-menu>
      </span>
    </div>
    <div class="col-lg-1"></div>
    <div></div>
  </nav>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mp01">
    <picture>
      <!-- Imagen para pantallas pequeñas (menos de 768px de ancho) -->
      <source media="(max-width: 360px)" srcset="assets/banner31.jpg">
  
      <!-- Imagen para dispositivos medianos (entre 768px y 991px de ancho) -->
      <source media="(min-width: 361px) and (max-width: 768px)" srcset="../../assets/banner31.jpg">
  
      <!-- Imagen para pantallas grandes (más de 991px de ancho) -->
      <source media="(min-width: 769px)" srcset="../../assets/banner3.jpg">
  
      <!-- Imagen por defecto si no se cumple ninguna de las consultas de medios -->
      <img src="../../assets/banner3.jpg" class="img-responsive imgbanner" alt="Banner">
    </picture>

  </div>
</header>