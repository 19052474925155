export const constantses = {
  LABEL_FIELD_REQUIRED : 'Campo obligatorio',
  LABEL_INVALID_EMAIL: 'Email inválido',
  LABEL_MAX_LENGTH_14: 'Máxima longitud 14 caracteres',
  LABEL_MIN_LENGTH_8: 'Mínima longitud 8 caracteres',
  LABEL_MIN_LENGTH_6: 'Mínima longitud 6 caracteres',
  LABEL_MAX_LENGTH_12: 'Máxima longitud 12 caracteres',
  LABEL_MAX_LENGTH_50: 'Máxima longitud 50 caracteres',
  LABEL_MIN_LENGTH_4: 'Mínima longitud 4 caracteres',
  LABEL_MAX_LENGTH_16: 'Máxima longitud 16 caracteres',
  LABEL_MIN_LENGTH_10: 'Mínima longitud 10 caracteres',
  LABEL_MAX_LENGTH_20: 'Máxima longitud 20 caracteres',
  LABEL_MIN_LENGTH_20: 'Mínima longitud 20 caracteres',
  LABEL_MAX_LENGTH_400: 'Máxima longitud 400 caracteres',
  LABEL_MAX_LENGTH_30:  'Máxima longitud 30 caracteres',
  LABEL_INVALID_WEB: 'Dirección de pagina web inválido',
  LABEL_INVALID_SOCIAL_NETWORK: 'Dirección de red social inválido',
  LABEL_INVALID_SOCIAL_FACEBOOK: 'Dirección web de facebook inválida',
  LABEL_INVALID_SOCIAL_TWITTER: 'Dirección web de twitter inválida',
  LABEL_INVALID_SOCIAL_YOUTUBE: 'Dirección web de youtube inválida',
  LABEL_INVALID_SOCIAL_INSTAGRAM: 'Direcció web de instagram inválida',
  LABEL_PASSWORD_NOTMACH: 'Los passwords no coinciden',
  LABEL_PASSWORD: 'Cambiar password',
  LABEL_SECURITY: 'Seguridad',
  LABEL_UPDATE: 'Actualizar',
  LABEL_AUTH: 'Ingresar',
  LABEL_ENTER: 'Ingresar',
  LABEL_SIGNUP: 'Regístrate',
  LABEL_CANCEL: 'Cancelar',
  LABEL_TYPE_USER_COMPETITOR: 'Competidor',
  LABEL_TYPE_USER_SPONSORING: 'Empresa patrocinadora',
  LABEL_LOSTPASSWORD: '¿ Olvidaste el password ? ',
  LABEL_PRIVACY_POLITICS: 'Políticas de privacidad',
  LABEL_TERMS_AND_CONDITIONS: 'Términos de uso',
  LABEL_CLOSE_SESSION: 'Cerrar sesión',
  LABEL_CLOSE: 'Cerrar',
  LABEL_SEND_PASSWORD: 'Recuperar password',
  LABEL_SEND: 'Enviar',
  LABEL_SIGNIN_FACEBOOK: 'Ingresar con Facebook',
  LABEL_SIGNUP_FACEBOOK: 'Registrarse con Facebook',
  LABEL_SIGNIN_GOOGLE: 'Ingresar con Google',
  LABEL_SIGNUP_GOOGLE: 'Registrarse con Google',
  LABEL_CREATE_ACCOUNT: 'Regístrate',
  LABEL_NOT_HAVE_ACCOUNT: '¿ No tienes una cuenta ?',
  LABEL_RECOVERY: ' Recuperar',
  LABEL_BACK_LOGIN: ' Regresar a autenticarse',
  LABEL_HAVE_ACCOUNT: '¿ Ya tienes una cuenta ?',
  LABEL_CONFIRM_EMAIL: '¡ Confirma tu email !',
  LABEL_CONFIRM_SENT: 'Se ha enviado un enlace de activación a tu dirección de correo electrónico.',
  LABEL_CONFIRM_SENT2: 'Se ha enviado un password temporal a tu dirección de correo electrónico.',
  LABEL_CHECK_EMAIL: 'Revisa tu bandeja de entrada ó carpeta de spam y haga clic en el enlace ',
  LABEL_CHECK_EMAIL0: '"activar usuario"',
  LABEL_CHECK_EMAIL2: 'para activar tu cuenta de usuario.',
  LABEL_CHECK_EMAIL3: 'Revise tu bandeja de entrada ó carpeta de spam y utiliza el password temporal que te enviamos para ingresar. ',
  LABEL_CHECK_EMAIL4: 'Para cambiarlo vaya a las opciones de seguridad.',
  LABEL_PASSWD_RECOVERED: 'Password recuperado',
  LABEL_GO_TO_PANEL: 'Panel',
  LABEL_PANEL: 'Panel',
  LABEL_CLOSE_SESION: 'Cerrar sesión',
  LABEL_HOME: 'Inicio',
  LABEL_HOWITWORK: 'Cómo funciona',
  LABEL_TEAM: 'El equipo',
  LABEL_SHOP: 'Tienda',
  LABEL_NEWS: 'Noticias',
  LABEL_CONTACT: 'Contactar',
  LABEL_CONTACTUS: 'Contáctanos.',
  LABEL_JOIN_TELEGRAM: 'Únete a Telegram',
  LABEL_CONTACTUS_ASK: '¿Cualquier pregunta? Comunícate con nosotros',
  LABEL_CONTACTUS_ASK2: 'y te responderemos a la brevedad.',
  LABEL_RESOURCES: 'Recursos',
  LABEL_WHITEPAPER: 'Whitepaper',
  LABEL_LINKS: 'Enlaces',
  LABEL_ROADMAP: 'Hoja de ruta',
  LABEL_TOKEN: 'El token',
  LABEL_SLOGAN: 'SLOGAN',
  LABEL_PROFILE: 'Perfil',
  LABEL_CHALLENGE: 'Retos',
  LABEL_ROUTES: 'Rutas',
  LABEL_AWARDS: 'Premios',
  LABEL_ADS: 'Publicidad',
  LABEL_PAYMENT: 'Pagos',
  LABEL_WALLET: 'Wallet',
  LABEL_STADISTICS: 'Estadísticas',
  LABEL_NAME: 'Nombre ó apodo',
  LABEL_NAME_CLIENT: 'Nombre de cliente',
  LABEL_SYMBOL: 'Símbolo',
  LABEL_DECIMALS: 'Decimales',
  LABEL_BLOCKCHAIN: 'Blockchain',
  LABEL_CONTRACT: 'Contrato',
  LABEL_EXPLORER: 'Explorador',
  LABEL_DAPP: 'Web Dapp',
  LABEL_CONTENT: 'Contenido',
  LABEL_SAVE: 'Actualizar',
  LABEL_PERSONAL_INFO: 'Información personal',
  LABEL_COMPANY_INFO: 'Datos básicos',
  LABEL_SOCIAL_NETWORKS: 'Redes sociales',
  LABEL_LOGO: 'Logo',
  LABEL_AVATAR: 'Avatar',
  LABEL_MEASUREMENTS: 'Medidas',
  LABEL_ADDRESS: 'Dirección',
  LABEL_SELECT_IMAGE: 'Seleccionar Imagen',
  LABEL_SIZE_MAX_LOGO: 'Tamaño máximo de imagen 500 Kb',
  LABEL_COMPETITOR_INFO: 'Información personal',
  LABEL_GENDER_FEMME: 'Femenino',
  LABEL_GENDER_MALE: 'Masculino',
  LABEL_WEIGHT: 'Peso',
  LABEL_HEIGHT: 'Altura',
  LABEL_METERS: 'Metros',
  LABEL_INCH: 'Pulgadas',
  LABEL_KILOGRAMS: 'Kilogramos',
  LABEL_EDIT_PROFILE: 'Editar perfil',
  LABEL_POUNDS: 'Libras',
  LABEL_LOADING_PROCESSING: 'Espere procesando',
  LABEL_IN_DEVELOPMENT: 'En desarrollo',
  LABEL_COMPLETED: 'Completado',
  LABEL_ENGLISH: 'Inglés',
  LABEL_SPANISH: 'Español',

  LABEL_HOME_WELCOME: 'Bienvenidos a  Force of Sales',
  // tslint:disable-next-line: max-line-length
  LABEL_HOME_1: 'SportGift es una plataforma conformada por una serie de tecnologías en la nube, microservicios, aplicación móvil, aplicación web y token del blockchain de ethereum que permitirá a los usuarios ganar premios por hacer ejercicios.',
  LABEL_HOME_2: 'La aplicación móvil servirá para que los competidores puedan participar en los retos y ganar premios.',
  // tslint:disable-next-line: max-line-length
  LABEL_HOME_3: 'La aplicación web permitirá a las empresas patrocinadoras registrarse, crear los retos deportivos, configurar los premios ó promociones y crear sus campañas publicitarias que serán publicadas en todos nuestros medios y redes sociales.',
  // tslint:disable-next-line: max-line-length
  LABEL_HOME_4: 'Los tokens SPORTG servirán para financiar el proyecto en su totalidad, como forma de pago en la tienda de sporgift, para pagar los anuncios publicitarios por parte de las empresas patrocinadoras, para lograr asociaciones y para el intercambio comercial del token en exchanges de criptomonedas.',

  LABEL_ROADMAP_WEB_1: 'Creación de arquitectura del sistema',
  LABEL_ROADMAP_WEB_2: 'Definición de estilos y capas de la web',
  LABEL_ROADMAP_WEB_3: 'Definición de la capa de seguridad',
  LABEL_ROADMAP_WEB_4: 'Creación de términos y condiciones',
  LABEL_ROADMAP_WEB_5: 'Desarrollo de los módulos del sistema',
  LABEL_ROADMAP_WEB_6: 'Integración con redes sociales',
  LABEL_ROADMAP_WEB_7: 'Creación de servicios web',
  LABEL_ROADMAP_WEB_8: 'Integración con el gateway de pago',
  LABEL_ROADMAP_WEB_9: 'Pruebas de QA',
  LABEL_ROADMAP_WEB_10: 'Marketing del sistema',

  LABEL_ROADMAP_MOVIL_1: 'Definición de la arquitectura de la app',
  LABEL_ROADMAP_MOVIL_2: 'Definición de estilos y capas',
  LABEL_ROADMAP_MOVIL_3: 'Creación de pantallas',
  LABEL_ROADMAP_MOVIL_4: 'Integración con servicios Web',
  LABEL_ROADMAP_MOVIL_5: 'Desarrollo de la capa de seguridad',
  LABEL_ROADMAP_MOVIL_6: 'Integración con el gateway de pago',
  LABEL_ROADMAP_MOVIL_7: 'Pruebas de QA',
  LABEL_ROADMAP_MOVIL_8: 'Despligue de la app en play store',
  LABEL_ROADMAP_MOVIL_9: 'Despligue de la app en apple store',
  LABEL_ROADMAP_MOVIL_10: 'Marketing de la app',

  LABEL_ROADMAP_GATEWAY_1: 'Elección de la tecnología a usar',
  LABEL_ROADMAP_GATEWAY_2: 'Creación de la arquitectura del proyecto',
  LABEL_ROADMAP_GATEWAY_3: 'Definición de modulos y funcionalidades',
  LABEL_ROADMAP_GATEWAY_4: 'Creación de la capa de seguridad',
  LABEL_ROADMAP_GATEWAY_5: 'Exposición de servicios web REST',
  LABEL_ROADMAP_GATEWAY_6: 'Integración con apis externos',
  LABEL_ROADMAP_GATEWAY_7: 'Integración con blockchains',
  LABEL_ROADMAP_GATEWAY_8: 'Pruebas de QA',
  LABEL_WALLET_ETHEREUM: 'Wallet Ethereum',
  LABEL_BALANCE: 'Saldos',
  LABEL_REFRESH_BALANCE: 'Refrescar saldo',
  LABEL_COMPETITOR: 'Para competidores',
  LABEL_COMPANY: 'Para empresas',
  LABEL_TASK: 'Tareas',

  LABEL_TITLE_NEWS: 'Encuentra las últimas noticias de SportGift',
  LABEL_OPEN: 'Abrir',
  LABEL_SHARE: 'Compartir',
  LABEL_BUY: 'Comprar',
  LABEL_SELL: 'En venta',
  LABEL_TITLE_STORE: 'Bienvenido a nuestra tienda virtual',

  LABEL_PRICE: 'Precio',
  LABEL_MIN_BUY: 'Cantidad mínima a comprar',
  LABEL_AMMOUNT: 'Cantidad',
  LABEL_TOTAL: 'Total',
  LABEL_PAY: 'Pagar',
  LABEL_WARNING_PAY: 'Debes estar autenticado',
  LABEL_VIEW_DETAIL: 'Detalle',
  LABEL_SELECT_FORM_PAYMENT: 'Seleccione forma de pago',
  LABEL_CHECKOUT_PAY: 'Proceder al pago',
  LABEL_QUESTION_TRANSACTION: '¿Desea enviar las criptomonedas para realizar la inversión?',
  LABEL_QUESTION_YES: 'Si',
  LABEL_QUESTION_NO: 'No',
  LABEL_CANCEL_TRANSACTION: 'Cancelar envio',
  LABEL_CLOSE_WINDOW: 'Cerrar',
  LABEL_TIME_LEFT: 'Tiempo restante',
  LABEL_SECONDS: 'Segundos',
  LABEL_TRANSACTION: 'Pago de franquicia',
  LABEL_QUESTION_ANUL_TRANSACTION: '¿Desea cancelar el envío de las criptomonedas?',
  LABEL_VIEW_TRANSACTION: 'Ver transacción',
  
  PLACEHOLDER_TYPE_MEASUREMENT_HEIGHT: 'Tipo medida altura',
  PLACEHOLDER_TYPE_MEASUREMENT_WEIGHT: 'Tipo medida peso',
  PLACEHOLDER_OLD_PASSWORD: 'Escriba el password actual',
  PLACEHOLDER_NEW_PASSWORD: 'Escriba el nuevo password',
  PLACEHOLDER_COMPETITOR_NAME: 'Nombre del competidor',
  PLACEHOLDER_FORMPAYMENT: 'Forma de pago',
  PLACEHOLDER_NAME: 'Nombre',

  SUBJECT_LOST_EMAIL : 'Password recuperado',
  SUBJECT_SIGNUP_SUCCESSFULL : 'Registrado con éxito',
  MSG_SUCCESSFULL_SAVE_PROFILE: 'Pefil guardado exitosamente',
  MSG_SUCCESSFULL_ACTIVATED: 'Usuario activado exitosamente',
  MSG_SUCCESS_SAVE_AVATAR: 'Se guardó el avatar exitosamente ',
  MSG_SUCCESS_SAVE_LOGO: 'Se guardó la imagen exitosamente ',
  MSG_SUCCESSFULL_SEND_COMMENT: 'Tu comentario fue enviado exitosamente, pronto será respondido.',
  MSG_SUCCESS_ANUL_TRANSACTION: 'Se anuló el pago del carrito de compras exitosamente',
  MSG_SUCCESSFUL_TRANSACTION: 'El pago de la inversión se ha efectuado con éxito.',
  MSG_SUCCESSFULL_UPDATED: 'Actualizado exitosamente',
  MSG_SUCCESS_TRANSACTION_ANULATED: 'Pago cancelado exitosamente',
  LB_WALLET_SAVED: 'Tu wallet fue guardada exitosamente',

  SUBJECT_SEND_COMMENTS : 'Comentario enviado',

  
  MSG_SUCCESSFULL_AUTH: 'Autenticado correctamente',
  MSG_SUCCESSFULL_SIGNUP: 'Registrado correctamente',
  MSG_SUCCESSFULL_PASSWORD_CHANGED: 'Password cambiado correctamente',
  MSG_SUCCESSFULL_SEND_PASSWORD: 'Se envio un correo electrónico con las instrucciones de recuperación de tu password',
  MSG_SUCCESSFULL_CLOSE: 'Tu sesión fue cerrada',
  
  MSG_SUCCESS_ACTIVATE: 'Se activó tu cuenta de usuario correctamente. ',
  MSG_SUCCESS_ACTIVATE2: 'Para ingresar al sistema use sus credenciales para autenticarse. ',
  
  MSG_SUCCESS_TRANSACTION: 'Se generó una wallet para el envío de los fondos',
  
  MSG_WARNING_TIMEOUT_TRANSACTION: 'Se venció el tiempo de la transaccion anterior',
  
  MSG_ERROR_PASSWORD_NOT_MATCH: 'El password anterior no es el correcto',
  MSG_ERROR_AUTH: 'Usuario ó password incorrecto',
  MSG_ERROR_PASSWORD_NOMATCH: 'La confirmación del password no coincide',
  MSG_ERROR_REPEAT_USER: 'El nombre de usuario ya está registrado',
  MSG_ERROR_REPEAT_EMAIL: 'El email ingresado ya está registrado, por favor use otro',
  MSG_ERROR_REGISTERED_SOCIAL: 'Usted ya se encuentra registrado, por favor autentiquese',
  MSG_ERROR_EMAIL_EXIST_SOCIAL: 'Alguien ya se registró con el email que usted esta usando en tu cuenta de red social',
  MSG_ERROR_SESSION_INVALID: 'Error tu sesión de usuario se venció, estas siendo redireccionado',
  MSG_ERROR_SESSION_INVALID_NR: 'Error tu sesión de usuario se venció, por favor autenticate de nuevo',
  MSG_LOST_PASSWORD: 'Por favor ingresa tu email para recuperar el password',
  MSG_ERROR_EMAIL_NOT_FOUND: 'Tu correo electrónico no esta registrado en el sistema',
  MSG_ERROR_NOT_REGISTERED_SOCIAL: 'No te has registrado aún, por favor registrate',
  MSG_ERROR_USER_INACTIVE: 'Usuario inactivo',
  MSG_ERROR_USER_NOT_FOUND: 'Usuario no encontrado',
  MSG_ERROR_SEND_EMAIL: 'Ocurrió un error al enviar el email, por favor inténtalo mas tarde',
  MSG_ERROR_USER_ACTIVE: 'El usuario ya esta activo',
  MSG_ERROR_USER_ACTIVATE: 'Ocurrió un error al activar el usuario',
  MSG_ERROR_ID_NOT_FOUND: 'El identificador del usuario no fue enviado en la petición. ',
  MSG_ERROR_ID_NOT_FOUND2: 'Por favor revisa tu enlace de activación y vuelva a intentarlo.',
  MSG_ERROR_ACTIVATE: 'Ocurrió un error al tratar de activar el usuario. ',
  MSG_ERROR_ACTIVATE_NOT_FOUND: 'El usuario no existe en nuestro sistema. ',
  MSG_ERROR_ACTIVATE_USER_ACTIVE: 'El usuario ya se encuentra activo, puede usar sus credenciales para autenticarse. ',
  MSG_ERROR_SAVE_PROFILE: 'Ocurrió un error al guardar el perfil',
  MSG_ERROR_DATA_PROFILE: 'Error al obtener los datos del perfil',
  MSG_ERROR_DATA_COUNTRY: 'Error al obtener los datos del país',
  MSG_ERROR_DATA_CITY: 'Error al obtener los datos de la ciudad',
  MSG_ERROR_IMAGEN_SIZE: 'El tamaño de la imagen sobrepasa al máximo permitido 150 Kb',
  MSG_ERROR_SAVE_IMAGE_PROFILE: 'Ocurrió un error al intentar guardar la imagen de perfil',
  MSG_ERROR_USER_SOCIAL_NETWORK: 'Tu cuenta de usuario pertenece a una red social, no puedes cambiarle la clave por este medio.',
  MSG_ERROR_LOGIN_SOCIAL_NETWORK: 'Tu cuenta de usuario pertenece a una red social, debes usar los botones de red social para autenticarse.',
  MSG_ERROR_HEIGHT: 'Ingrese el valor de la altura',
  MSG_ERROR_SELECT_HEIGHT: 'Seleccione el tipo de altura',
  MSG_ERROR_WEIGHT: 'Ingrese el valor del peso',
  MSG_ERROR_SELECT_WEIGHT: 'Seleccione un tipo de peso',
  MSG_ERROR_MEASUREMENTS: 'Hay errores en la sección de medidas, corríjalas por favor',
  MSG_ERROR_AUTHENTICATED: 'Ya te encuentras autenticado en el sistema, se te redireccionó al panel de usuario',
  MSG_ERROR_SEND_COMMENT: 'Ocurrió un error al enviar el comentario, por favor inténtalo mas tarde',
  MSG_ERROR_PASSWORD_SAME_OLD: 'El nuevo password es igual al anterior password, por favor modífiquelo',
  MSG_ERROR_ACCESS_INVALID: 'Acceso inválido',
  MSG_ERROR_CAPTCHA: 'Usted no aprobó la verificación del captcha v3 de google',
  MSG_ERROR_GET_WALLET: 'Error al obtener una wallet',
  MSG_ERROR_MAX_REQUEST_WALLET: 'Usted realizó muchas solicitudes consecutivas debe esperar unos minutos',
  MSG_ERROR_USER_INVALID: 'Usuario o clave incorrecta',
  MSG_ERROR_ID_NEWS: 'Ocurrió un error, no fue entregado el identificador de la noticia, se redirecciona',
  MSG_ERROR_NEWS_NOT_FOUND: 'La noticia no puede ser encontrada',
  MSG_ERROR_ARTICLE_NOT_FOUND: 'Error articulo no puede ser encontrado',
  MSG_ERROR_EMAIL_REQUIRED_SOCIAL: 'Error al registrarse, tu cuenta de red social no posee email',
  MSG_ERROR_CRYPTO_SELECT: 'Debe seleccionar una moneda',
  MSG_ERROR_API_PRECIO: 'Error al consultar el precio, por favor inténtalo mas tarde',
  MSG_ERROR_CANCEL_TRANSACTION: 'Error cancelando el envío de criptomonedas, por favor inténtalo mas tarde',
  // tslint:disable-next-line: max-line-length
  MSG_ERROR_TIMEOUT_TRANSACTION:'La transacción se canceló por agotarse el tiempo de espera.',
  MSG_ANUL_TRANSACTION: 'Se ha anulado el envío de criptomonedas ',
  MSG_TIMEOUT_TRANSACTION: 'Se ha terminado el tiempo para procesar la transaccion ',

  ROLE_COMPETITOR: 'USER_ROLE',
  ROLE_COMPANY: 'SPONSORING_ROLE',

  TITLE_ERROR_ACTIVATE_USER: '! Error al activar el usuario !',
  TITLE_ACTIVATE_USER_SUCESS: '! Activación exitosa !',
  TITLE_SMART_CONTRACT: 'Nuestro Smart Contract',
  TITLE_INFO_CONTRACT: 'Token',

  PLACEHOLDER_USERNAME: 'Usuario',
  PLACEHOLDER_COMPANY_NAME: 'Nombre',
  PLACEHOLDER_EMAIL: 'Email',
  PLACEHOLDER_CLIENT_EMAIL: 'Email de cliente',
  PLACEHOLDER_TYPEUSER: 'Tipo de usuario',
  PLACEHOLDER_PASSWORD: 'Password',
  PLACEHOLDER_REPEAT_PASSWORD: 'Confirmar password',
  PLACEHOLDER_RIC_RUC: 'Rif ó Ruc',
  PLACEHOLDER_WEB: 'Sitio web',
  PLACEHOLDER_LOGO: 'Logo',
  PLACEHOLDER_TWITTER: 'Twitter',
  PLACEHOLDER_FACEBOOK: 'Facebook',
  PLACEHOLDER_INSTAGRAM: 'Instagram',
  PLACEHOLDER_SNAPCHAT: 'Snapchat',
  PLACEHOLDER_COUNTRY: 'País',
  PLACEHOLDER_CITY: 'Ciudad',
  PLACEHOLDER_ADDRESS: 'Dirección',
  PLACEHOLDER_PHONE: 'Teléfono casa',
  PLACEHOLDER_MOVIL: 'Teléfono celular',
  PLACEHOLDER_BIRTHDATE: 'Fecha de nacimiento',
  PLACEHOLDER_GENDER: 'Genero',
  PLACEHOLDER_YOUTUBE: 'Youtube',

  ICON_EMAIL: 'mail_outline',
  ICON_ERROR: 'highlight_off',
  ICON_SUCCESS: 'check',
  ICON_TIEMOUT: 'timer',

  CONTENT_CONTACT: 'Contáctanos',
  CONTENT_ASK1: '¿Cualquier pregunta? Comunícate con nosotros',
  CONTENT_ASK2: 'y te responderemos a la brevedad.',
  CONTENT_PHON: '+000 000 000 00',
  CONTENT_EMAIL: 'info@sportgift.io',
  CONTENT_JOIN_TELEGRAM: 'Unete a Telegram @sportgift_official',

  CONTENT_SPORTGIFT_ROADMAP: 'SportGift Roadmap 2020',
  CONTENT_APP_WEB: 'Aplicación web ',
  CONTENT_APP_MOVIL: 'Aplicación móvil ',
  CONTENT_GATEWAY: 'Gateway de pago',
  CONTENT_WHITEPAPER: 'Whitepaper de SportGift',
  CONTENT_TITLE_ROADMAP: 'Hoja de ruta de desarrollo de SportGift',
  // tslint:disable-next-line: max-line-length
  CONTENT_DESCRIPTION_ROADMAP: 'Nuestra hoja de ruta de desarrollo ofrece una visión general de nuestros próximos proyectos, planes, hitos y más. Eche un vistazo en lo que estamos trabajando.',
  CONTENT_TAREAS: 'tareas de',
  // tslint:disable-next-line: max-line-length
  CONTENT_COMPLETED: 'completadas',
  // tslint:disable-next-line: max-line-length
  CONTENT_DESC_WEB: 'La aplicación web de SportGift, es una plataforma en la nube desarrollada con la última tecnología, que permitirá a las empresas patrocinadoras registrarse para poder crear los retos, crear las campañas publicitarias, pagar para publicar los retos, editar su perfil y ver datos estadísticos, entre otras cosas. También permitirá a los usuarios competidores editar su perfil, ver sus estadísticas y su wallet.',
  // tslint:disable-next-line: max-line-length
  CONTENT_DESC_WEB2: 'En la web se publicarán noticias importantes acerca del proyecto y del token, así como información relevante de la aplicación. Este módulo forma una parte esencial de los 4 componentes del proyecto. App web, App movil, Gateway de pago y Token del proyecto.',
  // tslint:disable-next-line: max-line-length
  CONTENT_DESC_MOVIL: 'La aplicación móvil es la base fundamental del proyecto y le permitirá al usuario participar en los retos deportivos, realizar el seguimiento de la ruta, solicitar el pago de su premio, participar en competiciones con sus conocidos de sus redes sociales, ver noticias de último momento, entre otras grandes funcionalidades que estamos pesando desarrollar a lo largo del proyecto.',
  // tslint:disable-next-line: max-line-length
  CONTENT_DESC_GATEWAY: 'El gateway de pago es el motor transaccional de pagos dentro de la plataforma, que permitirá a los competidores y empresas patrocinadoras hacer uso del token SPORTG para intercambiar por artículos disponibles en la tienda virtual, pagar retos, pagar publicidades dentro de la aplicación, retirar sus tokens a una wallet, entre otras cosas, el token pronto estará disponible en exchanges de gran importancia.',
  // tslint:disable-next-line: max-line-length
  CONTENT_DESC_WHITEPAPER: 'El documento técnico de SportGift es un excelente lugar para aprender más sobre el proyecto. Describe todo lo que necesita saber, desde la misión y visión de SportGift, hasta cómo funcionará la aplicación, entre otros puntos. Si desea profundizar en SportGift y realmente entender exactamente lo que representamos, descargue nuestro documento técnico para comenzar.',

  INFOCOMPETITOR: 'infocompetitor-es.png',  
  INFOCOMPANY: 'infocompany-es.png',
  LABEL_NOT_HAVE_CODE: '¿ No he recibido el código ?',
  LABEL_RESEND_CODE: 'Reenviar el código',
  LABEL_ENTER_CODE: 'Ingresa CÓDIGO DE VERIFICACIÓN enviado a tu correo electrónico',
  LABEL_TEXT_CODE : '* Código: Número de 6 dÍgitos generado por la aplicación Google Authenticator',
  LABEL_MIN_LENGTH_5:   'Máxima longitud 5 digitos',
  SUBJECT_ACCESS_CODE : 'Código de verificación',
  MSG_ERROR_USER_CODE: 'Código de verificación incorrecto',
  MSG_ERROR_MINUTES_EXCEED: 'Se venció el código de verificación',
  LABEL_PARNERTS: 'ASOCIADOS',
  LABEL_CONFIG: 'Configuración',  
  LABEL_TRANSACTIONS: 'Pagos', 
  LABEL_BLOCKCHAINS: 'Blockchains',
  LABEL_RESULT: 'Respuesta',
  LABEL_CONFIGURATION: 'Configuración',
  LABEL_PARAMETER: 'Parámetros',  
  LABEL_TEXT_PARAMETERS_NOTE : 'Nota',
  LABEL_TEXT_PARAMETERS : 'La dirección url invocada deberia construirse con los parámetros en el orden como se ven y sustituyendo los valores correspondientes por los propios',
  LABEL_TEXT_PARAMETERS_ONE: 'Para poder cobrar a sus clientes usando nuestro gateway de pagos, usted debe construir una url llamando a  Force of Sales con una serie de parámetros como los que se  muestran en la tabla y en el mismo orden.',
  LABEL_POSITION: 'Orden',   
  LABEL_MANDATORY: 'Obligatorio', 
  LABEL_DESCRIPTION: 'Descripción', 
  LABEL_EXAMPLE: 'Ejemplo', 
  LABEL_DESCRIPTION_PROFILE_ID: 'Es el identificador de perfil que servirá para asociar los pagos efectuados de sus clientes hacia su tienda. Puede ser encontrado al editar su perfil.', 
  LABEL_DESCRIPTION_AMOUNT: 'Es el monto en dólares a pagar, debe ser númerico y los decimales deben estar separados por punto. Por ejemplo 41.05.', 
  LABEL_DESCRIPTION_BUYER_NAME: 'Es el nombre del comprador que se mostrará en la pantalla y en el correo a enviar.', 
  LABEL_DESCRIPTION_BUYER_EMAIL: 'Es el correo del comprador, es un dato obligatorio.', 
  LABEL_DESCRIPTION_DESCRIPTION: 'Es la descripción de lo que se esta pagando, este dato es opcional.', 
  PLACEHOLDER_PROFILE_ID: 'Profile ID',
  LABEL_YES: 'Si',
  LABEL_NO: 'No',
  LABEL_DESCRIPTION_PURCHASE_ID: 'Es el identificador del carrito de compra o id de compra, sirve para enviar la respuesta y notificarle a su tienda el resultado final de la transacción.',
  MSG_ERROR_API_BLOCKCHAINS:  'Error al obtener los blockchains disponibles',
  LABEL_TEXT_BLOCKCHAINS:'Seleccione los blockchains que se usarán para recibir los pagos',
  MSG_ERROR_UPDATE_BLOCKCHAINS:'Ocurrió un error al actualizar los blockchains',
  
  LABEL_SUSTITUTE_URL: 'sustituir los valores correspondientes por los propios', 

  LABEL_ERROR_PROFILE_ID: 'El parámetro profileId no es valido',
  LABEL_ERROR_AMOUNT: 'El parámetro monto de pago no es válido ',
  LABEL_ERROR_PURCHASE_ID: 'El parámetro id de compra no es válido ',
  LABEL_ERROR_BUYER_NAME:  'El parámetro nombre de comprador es inválido',
  LABEL_ERROR_BUYER_EMAIL: 'El parámetro email del comprador es inválido',
  LABEL_ERROR_DESCRIPTION: 'La descripción es inválida',
  LABEL_CLIENT_DATA: 'Cliente',
  LABEL_AMOUNT_SEND: 'Enviar',
  PLACEHOLDER_DESCRIPTION: 'Descripción de pago',
  LABEL_STORE_DATA:'Empresa',
  LABEL_VALUE_TO_PAY:'Monto invertido',
  LABEL_PAYMENT_ACTION: 'Pago',
  LABEL_PRICE_OF: 'Precio de',
  LABEL_AMOUNT_PAY: 'Pago',
  MSG_ERROR_PROFILE_NOT_FOUND: 'El identificador del profile no fue encontrado',
  MSG_ERROR_CRYPTOS_NOT_FOUND: 'No se encontraron cryptomonedas configuradas para esta cuenta',
  
  MSG_ERROR_TRANSACTION: 'Error al crear el envío de criptomonedas, por favor intente mas tarde',
  MSG_ERROR_TRANSACTION_NOT_FOUND: 'Transacción no encontrada',
  LABEL_CHECK_TRANSACION: 'Consultar pago',
  LABEL_SEND_ISSUE: 'Reportar problema',
  LABEL_SPAIN: 'España',
  LABEL_HOME_INTRO: 'Multiplica tu dinero de forma segura',
  LABEL_HOME_INTRO_2: '',
  LABEL_HOME_INTRO_3: 'Para poder usar la pasarela de pago como un comerciante regístrese, ingrese a su cuenta de usuario y empieza a revisar las bondades del sistema. Actualmente estamos en fase de desarrollo y pruebas, por lo que vera algunos módulos en fase de construcción',
  LABEL_HOME_INTRO_4:'',
  LABEL_HOW_IT_WORK1: '¿Cómo funciona  Force of Sales? ',
  LABEL_HOW_IT_WORK2: 'Usted podrá recibir pagos de terceros con criptomonedas a través del uso de  Force of Sales que es nuestro gateway de pago y uno de nuestros productos estrella de una forma muy sencilla mediante los siguientes pasos:',
  LABEL_HOW_IT_WORK3: 'Para poder usar el Gateway de pagos debe redireccionar a sus clientes hacia  Force of Sales construyendo una url con unos parámetros específicos que encontrará cuando ingrese a su cuenta de usuario en la sección [configuración -> paramétros]',
  LABEL_HOW_IT_WORK4: '1. Cree una cuenta de usuario registrandose en  Force of Sales',
  LABEL_HOW_IT_WORK5: '2. Active su cuenta de usuario haciendo click en el link que le llegará a su correo',
  LABEL_HOW_IT_WORK6: '3. Configure su perfil seleccionando las monedas que aceptará en su tienda',
  LABEL_HOW_IT_WORK7: '4. En su tienda o sistema configure la url para recibir sus pagos mediante  Force of Sales',
  LABEL_HOW_IT_WORK8: '5. Consulte todos sus pagos en  Force of Sales cuando los haya recibido',
  LABEL_HOW_IT_WORK9: '6. Retire sus fondos',
  LABEL_IN_CONSTRUCTION: 'Estamos desarrollando este módulo',
  LABEL_NAME_PARAM:'Nombre de parámetro',
  LABEL_PRICE_REFRESH: 'El precio se actualizará en: ',
  MSG_ERROR_INVALID_EMAIL: 'El email ingresado es invalido',
  TOTAL_FUNDS:  'Monto Invertido (USD)', 
  TRANSACTIONS_HISTORY: 'Últimas 10 inversiones',
  LABEL_DATE: 'Fecha',
  LABEL_AMOUNT: 'Monto',
  LABEL_CLIENT: 'Cliente',
  LABEL_STATUS: 'Estatus',
  LABEL_CRYPTO: 'Criptomoneda',


  LABEL_CHECK_TRANSACTION: 'Consultar pago',
  LABEL_TRANSACTION_ID: 'ID',  
  LABEL_MSG_TRANSACTION: 'Ingrese el identificador de transacción enviado a tu correo electrónico',
  LABEL_QUERY: 'Consultar',
  LABEL_TRANSACTION_DATA: 'Datos de pago',
  LABEL_CREATION_DATE: 'Fecha',
  LABEL_CRYPTO_TO_SEND: 'Criptos enviadas',
  LABEL_WALLET_TRANSACTION: 'Wallet',
  LABEL_STATUS_TRANSACTION: 'Estatus',
  LABEL_PROCESS_ON: 'Procesado el', 
  LABEL_CHECK_ANOTHER: 'Consultar otra', 
  LABEL_TRANSACTION_CANCEL: 'Transacción cancelada',
  LABEL_TRANSACTION_TIMEOUT: 'Se terminó el tiempo',
  LABEL_TRANSACTION_ERROR: 'Transacción con errores',
  LABEL_TRANSACTION_SUCESSFULL: 'Éxitosa',
  LABEL_TRANSACTION_INCOMPLETE: 'Incompleta',
  MSG_TRANSACTION_FOUND: 'Transacción encontrada y activa',
  LABEL_SELECT: 'Seleccionar',
  LABEL_VIEW_DETAILS: 'Detalles',

  
  STATUS_TRANSACTION_PENDENT: 'Revisando envio',  
  STATUS_TRANSACTION_SUCESSFULL: 'Envio exitoso',
  STATUS_TRANSACTION_INCOMPLETE: 'Pago incompleto',
  STATUS_TRANSACTION_CANCEL: 'Envio cancelada',
  STATUS_TRANSACTION_PAYMENT_SUCCESS: 'Pagada',
  STATUS_TRANSACTION_TIMEOUT: 'Timeout',
  STATUS_TRANSACTION_ACTIVE:'Pagado',
  STATUS_TRANSACTION_ERROR: 'Error',
  LABEL_VALUE: 'Valor',
  LABEL_ACTION:'Acción',
  LABEL_BOUGHT: 'Comprados',
  LABEL_CONDITIONS:'Condiciones',
  LABEL_PRIVATE_SALE:'Venta privada exclusiva',
  LABEL_PRIVATE_PROGRESS:'Progreso',
  LB_TOKEN_SALE: 'Tokens en venta',
  LB_REFERRALS:'Bono universal',
  LB_AMOUNT_INVESTED: 'Monto invertido',
  LB_TOKENS_RECEIVED:'Tokens a recibir',
  LABEL_VIEW: 'Ver',
  LB_INFO_TOKENS: '* Los tokens $PEDRO serán enviados a su wallet personal BNB Smart chain, si aún no ha guardada una wallet por favor ingrese a la opción del menú [Wallet].',
  LB_EARN_REFERRALS:'Total ganancias',
  LB_LINK_REFERRAL: 'Tu enlace de referido es',
  LB_LAST_REFERRAL: 'Últimos 10 Referidos',  
  LB_DATE_INVESTMENT: 'Fecha inversión',  
  LB_NAME_REFERRAL :'Nombre referido',
  LB_INVESTMENT_AMOUNT:'Monto invertido',
  LB_COMI_USDT:'Comisión $USDT',
  LB_COMI_TOKENS:'5% de commisión Tokens',
  LB_EARN_USDT:'* Las ganancias obtenidas por franquiciados serán enviadas a tu wallet en la moneda USDT(BEP20). Si aún no has guardado una wallet personal BNB Smart chain por favor ingrese a la opción del menú [Wallet].',
  LB_AMOUNT:'Monto',
  LB_REFERRAL:'Referido',
  LB_INVEST:'Inversión',
  LB_FOR_SENT:'Por enviar una wallet',
  LB_SENT:'Enviado a tu wallet',
  LB_CODE_REFERER: 'Código de referido',
  LB_REFERRER_BY: 'Refererido por',
  LB_WALLET_ENTER: 'Debes ingresar una wallet para poder guardar',
  LB_WALLET_BNB: 'La wallet USDT presenta un formato incorrecto que no se ajusta a la cadena de bloques BNB Smart Chain. Por favor, procede a realizar la corrección correspondiente.',
  LB_WALLET_COPIED: 'Wallet copiada!!',
  LB_PAYMENT_REFERRALS: 'Bono universal',
  LB_VIEW_REFERRAL:'Ver referidos', 
  LB_REFERRALAS:'Referidos',
  LB_INVESTMENTS: 'Pagos',
  LB_INVEST_: 'Pagar franquicia',
  LB_SELECT_AMOUNT:'Pagar franquicia',
  LB_PRICE_TOKEN:'Precio por token',
  LB_TO_RECEIVE: 'A recibir',
  LB_SELECT_PAYMENT: 'Seleccionar pago',
  LB_SELECTED_PAYMENT: 'Pago seleccionado',  
  LB_TO_SEND: 'Debe enviar',  
  LB_SELECT_OTHER: 'Seleccionar otra',
  LB_MUST_SEND: 'Debe enviar',
  LB_SEND: 'Realizar envio',   
  LB_Q1: '¿Desea enviar los ', 
  LB_Q2:'al sistema para realizar tu inversión?',
  LB_VERIFY:'Verificar envio',
  LB_ANULATE:'Anular envio',
  LB_SIGN_UP_HOME:'Regístrate en nuestro sistema para poder participar de la venta privada de  Force of Sales, obteniendo hasta 50% de BONUS.',
  LB_START_DATE:'Fecha de inicio: Lunes 26 Junio 2023',
  LB_END_DATE:'Fecha de fin: Lunes 10 Julio 2023',  
  LB_TOKENS_SALE:'Tokens en venta',
  LB_MIN_INVESTMENT:'Inversión mínima',
  LB_PICE_TOKEN:'Precio por token',
  LB_BNB_WALLET:'Debes tener configurada una wallet BNB chain para recibir sus tokens y sus comisiones por referido.',
  LB_IMPORTANT:'Importante',  
  LB_SGININ_REPEAT:'You are already authenticated',
  LB_CODE_REFERAL:'Se ha actualizado el código del referidor',
  LB_COPIED_LINK:'Enlace de invitación copiado!',
  LB_ERROR_PROGRESS: 'Error al procesar el progreso de la venta privada',
  LB_TOTAL_INVESTMENT:'Total invertido',
  LB_USDT_COMISSION:'Comisión $USDT',
  LB_PEDRO_COMISSION:'Comisión $PEDRO',
};


