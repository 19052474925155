<app-header></app-header>
<div class="container-fluid estilo-login nueve">
  <div class="row">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4">
      <div class="form-container">
        <mat-card class="example-card rosado">
          <mat-card-header class="center-block">
            <mat-card-title >{{constants.LABEL_SEND_PASSWORD}}</mat-card-title>
          </mat-card-header><br>
          <mat-card-content style="text-align:center">
            <form [formGroup]="passwordLostForm" (ngSubmit)="onSubmit(passwordLostForm.value)">
              <!--<img class="img-fluid" src="../../assets/logo-white2x.png">-->
              <mat-form-field class="inputWidth">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="Email" autocomplete="off">
                <mat-icon matSuffix color>email</mat-icon>
                <mat-error *ngIf="passwordLostForm.invalid">{{msgValidateEmail()}}</mat-error>
              </mat-form-field>
              <div><span class='infoform'>* {{constants.MSG_LOST_PASSWORD}}</span></div><br>
              <div style="clear:both" >
                  <button mat-raised-button color="primary" type="submit" [disabled]="passwordLostForm.invalid" class="width60">{{constants.LABEL_RECOVERY}}</button><br><br>
                  <a routerLink="../{{global.ROUTE_LOGIN}}" class="text-anchor">{{constants.LABEL_BACK_LOGIN}}</a>
              </div>
            </form>
          </mat-card-content>
          </mat-card>
        </div>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
</div>
<app-footer></app-footer>