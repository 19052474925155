import { Component, OnInit, ViewChild } from '@angular/core';
import {AuthLoginService} from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonsService} from 'src/app/services/commons/commons.service';
import { global } from 'src/app/constants/global';
import { MatMenu } from '@angular/material/menu';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  providers: [AuthLoginService, CommonsService]
})
export class AdminLayoutComponent implements OnInit {
  MENU_USER: any[];  
  constants: any;
  global: any;
  datos: {role?: any, ok?: false};
  isExpanded = true;
  isShowing = true;
  showSubmenu: boolean = true;  
  showSubSubMenu: boolean = false;
  @ViewChild('service_menu') serviceMenu: MatMenu;

  constructor(
    private readonly commonsService: CommonsService,
    private readonly authLoginService: AuthLoginService,
    public router: Router,
    private toastr: ToastrService) { }

    
   navigate(){
      window.open("../../../assets/presentacion.pdf", '_blank');
   }

  ngOnInit(): void {
    this.constants = this.commonsService.getConstants();    
    this.global = global;

    const MENU_CHILD_BONUS =  [
      {menu: "Bono inicial", route: global.ROUTE_INITIAL_BONUS, icon: 'group'},
      {menu: "Bono Matriz", route: global.ROUTE_UNIVERSAL_BONUS, icon: 'grain'},
      {menu: "Bono VIP", route: global.ROUTE_VIP_BONUS, icon: 'star'},
    ];

    const MENU_CHILD_ADMIN =  [      
      {menu: "Inversores", route: global.ROUTE_ADMIN_FRANQUICIAS, icon: 'people'},
      {menu: "Retiros", route:  global.ROUTE_ADMIN_RETIROS, icon: 'account_balance'},
      {menu: "Inversiones", route:  global.ROUTE_ADMIN_PAGOS, icon: 'local_atm'}
    ];

    const MENU_ROLE_USER =  [
      {menu: "Inicio", route: "../"+global.ROUTE_DASHBOARD, icon: 'dashboard'},      
      {menu: "Ganancias", route: null, icon: 'monetization_on', child: MENU_CHILD_BONUS},      
      {menu: "Inversiones", route: global.ROUTE_TRANSACTIONS, icon: 'receipt'},      
      {menu: "Wallet", route: global.ROUTE_WALLET, icon: 'account_balance_wallet'},      
      {menu: this.constants.LABEL_SECURITY, route: global.ROUTE_SECURITY, icon: 'https'},      
      {menu: "Presentación", route: "presentacion", icon: 'slideshow'},      
    ];

    const MENU_ROLE_ADMIN =  [
      {menu: "Inicio", route: "../"+global.ROUTE_DASHBOARD, icon: 'dashboard'},      
      {menu: "Invertir", route: global.ROUTE_TRANSACTIONS, icon: 'poll'},
      {menu: "Ganancias", route: null, icon: 'monetization_on', child: MENU_CHILD_BONUS},           
      {menu: "Wallet", route: global.ROUTE_WALLET, icon: 'account_balance_wallet'},      
      {menu: this.constants.LABEL_SECURITY, route: global.ROUTE_SECURITY, icon: 'https'},     
      {menu: "Administrar", route: global.ROUTE_DASHBOARD, icon: 'assignment_turned_in', child: MENU_CHILD_ADMIN},
      {menu: "Presentación", route: "presentacion", icon: 'slideshow'},      
    ];
        
    this.MENU_USER = MENU_ROLE_USER;

    
    this.authLoginService.getRole().subscribe (
      result => {
        this.datos = result;
        if (this.datos.role === this.global.ROLE_USER) {
          this.MENU_USER = MENU_ROLE_USER;
        } else if (this.datos.role === this.global.ROLE_ADMIN) {
          this.MENU_USER = MENU_ROLE_ADMIN;          
        }
      },
      error => {
        this.authLoginService.verifySessionWS(error);
      }
    );
  }

  closeSession() {
    this.authLoginService.closeSession();
    this.toastr.info(this.constants.MSG_SUCCESSFULL_CLOSE);
    this.router.navigate([global.ROUTE_HOME]);
  }

}

