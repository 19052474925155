import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecoverysuccessComponent } from './modules/lostpassword/recoverysuccess/recoverysuccess.component';
import { ActivateuserComponent } from './modules/signup/activateuser/activateuser.component';
import { AdminLayoutComponent } from './modules/dashboard/admin-layout/admin-layout.component';
import { global } from 'src/app/constants/global';
import { SignupComponent } from './modules/signup/signup.component';
import { LostpasswordComponent } from './modules/lostpassword/lostpassword.component';
import { LoginComponent } from './modules/login/login.component';
import { PagenotfoundComponent } from './modules/pagenotfound/pagenotfound.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: global.ROUTE_HOME, component: LoginComponent },
  { path: global.ROUTE_LOGIN, component: LoginComponent },
  { path: global.ROUTE_PASSWORDRECOVERY, component: LostpasswordComponent },
  { path: global.ROUTE_SIGNUP, component: SignupComponent },
  { path: global.ROUTE_SIGNUP + '/:referralCode', component: SignupComponent },
  { path: global.ROUTE_SIGNUP + '/:referralCode/:referfortheMember', component: SignupComponent },
  { path: global.ROUTE_RECOVERYSUCCESS, component: RecoverysuccessComponent },
  { path: global.ROUTE_ACTIVATE_USER_SUCCESS, component: ActivateuserComponent },
  {
    path: global.ROUTE_DASHBOARD,
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/dashboard/admin-layout/admin-layout.module')
      .then(m => m.AdminLayoutModule),
  },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
