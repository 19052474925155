import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CommonsService} from 'src/app/services/commons/commons.service';
import { global } from 'src/app/constants/global';
import { AuthLoginService } from '../services/auth/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [AuthLoginService, CommonsService]
})
export class FooterComponent implements OnInit {
  public constants: any;
  public global: any;
  dialogRef: any;
  url: any;
  year = 0;

  constructor(
    public dialog: MatDialog,
    private readonly authLoginService: AuthLoginService,
    private readonly commonsService: CommonsService) {
  }

  ngOnInit(): void {
    this.global = global;
    this.constants = this.commonsService.getConstants();
    const date = new Date();
    this.year = date.getFullYear();
        
  }

  isLoggedIn() {
    return this.authLoginService.isLoggedIn();
  }

}
