<footer >


  
  <nav class="bd-navbar footer-lungwort">
    <div class="container">
      <div class="row" *ngIf="isLoggedIn()">
        <div class="col-lg-12 text-center ">
          <a href="https://www.instagram.com/force_of_sales/" target="_blank"><i class="pi pi-instagram icon-profile hand"></i></a>
            &nbsp;
            <a href="https://t.me/forceofsales" target="_blank"><i class="pi pi-telegram icon-profile hand"></i></a>
        </div>
      </div>
      <div class="row pd20" >
        <div class="col-lg-12 text-center ">
          <span class="copyright">Copyright @{{year}} Force of Sales. Todos los derechos reservados</span>
        </div>
      </div><br><br>
    </div>
  </nav>
  
</footer>