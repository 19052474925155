<app-header></app-header>
<div class="container-fluid estilo-login pd40" *ngIf="!isSigned">  
  <div class="row cinco" *ngIf="errorReferred">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4 text-center">
      <mat-card class="example-card rosado">
        <mat-icon matSuffix color="primary" class="icon-big">error_outline</mat-icon>
        <mat-card-header class="center-block">
          <p class="title_home_adver">Se requiere un enlace de referido para registrarte. Pide a tu contacto su enlace para proceder.</p>
        </mat-card-header>        
        <mat-card-actions>
          <p class="text-center"><button mat-raised-button color="primary" class="size-button"
              routerLink="../{{global.ROUTE_HOME}}">Regresar</button></p><br>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
    
  <div class="row tres" *ngIf="!is_investor && !errorReferred">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4 text-center">
        <mat-card class="rosado blue_light">
        <mat-icon matSuffix color="primary" class="icon-big">error_outline</mat-icon>
        <mat-card-header class="center-block">          
            <p class="title_home_adver">Para poder registrarte, el propietario del enlace correspondiente debe completar 
              una inversión.</p><br>              
        </mat-card-header> 
        <mat-card-actions>
          <p class="text-center"><button mat-raised-button color="primary" class="size-button"
              routerLink="../{{global.ROUTE_HOME}}">Regresar</button></p><br>
        </mat-card-actions>
        </mat-card>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>

  <div class="row tres" *ngIf="!errorReferred && levelOneFilled">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4 text-center">
        <mat-card class="rosado blue_light">
        <mat-icon matSuffix color="primary" class="icon-big">error_outline</mat-icon>
        <mat-card-header class="center-block">          
            <p class="title_home_adver">No es posible registrarse con este enlace de referido, ya que su propietario 
              ha completado su primer nivel de la matriz 2x3. Te recomendamos solicitar otro enlace de registro.</p><br>              
        </mat-card-header> 
        <mat-card-actions>
          <p class="text-center"><button mat-raised-button color="primary" class="size-button"
              routerLink="../{{global.ROUTE_HOME}}">Regresar</button></p><br>
        </mat-card-actions>          
        </mat-card>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>

  

  <div class="row tresb" *ngIf="!errorReferred && !levelOneFilled && is_investor">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4">
      <div class="form-container" style="text-align: center">
        <mat-card class="example-card rosado">
          <mat-card-header class="center-block">
            <mat-card-title class="pd20">{{constants.LABEL_SIGNUP}}</mat-card-title>
          </mat-card-header><br>
          <mat-card-content style="text-align:center">

            <form [formGroup]="signUpForm" (ngSubmit)="onSave(signUpForm.value)">
              <mat-form-field class="inputWidth">
                <mat-label>Nombre ó apodo</mat-label>
                <input matInput formControlName="name" placeholder="Nombre ó apodo" autocomplete="off" required>
                <mat-error *ngIf="signUpForm.invalid">{{msgValidateName()}}</mat-error>
              </mat-form-field><br>

              <mat-form-field class="inputWidth">
                <mat-label>{{constants.PLACEHOLDER_EMAIL}}</mat-label>
                <input matInput formControlName="email" placeholder="{{constants.PLACEHOLDER_EMAIL}}"
                  autocomplete="off" required>
                <mat-icon matSuffix color>email</mat-icon>
                <mat-error *ngIf="signUpForm.invalid">{{msgValidateEmail()}}</mat-error>
              </mat-form-field><br>

              <mat-form-field class="inputWidth">
                <mat-label>Referido directo de</mat-label>
                <input matInput formControlName="coderefered" placeholder="Referido directo de" readonly
                  autocomplete="off" required>
              </mat-form-field><br><br>

              <mat-form-field class="inputWidth" *ngIf="thereIsCodeMember">
                <mat-label>Referido para el miembro</mat-label>
                <input matInput formControlName="codereferedMember" placeholder="Referido para el miembro" readonly
                  autocomplete="off" required  *ngIf="thereIsCodeMember">
              </mat-form-field><br *ngIf="thereIsCodeMember"><br *ngIf="thereIsCodeMember">

              <mat-form-field class="inputWidth">
                <mat-label>{{constants.PLACEHOLDER_MOVIL}}</mat-label>
                <input matInput formControlName="phonecel" placeholder="{{constants.PLACEHOLDER_MOVIL}}"
                  autocomplete="off" maxlength="16" (keydown)="checkNumber($event)" required>
                <mat-icon matSuffix color>phone_iphone</mat-icon>
                <mat-error *ngIf="signUpForm.invalid">{{msgValidateCelPhone()}}</mat-error>
              </mat-form-field><br><br>

              <mat-form-field class="inputWidth">
                <mat-label>{{constants.PLACEHOLDER_PASSWORD}}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
                  placeholder="{{constants.PLACEHOLDER_PASSWORD}}" #password maxlength="14" autocomplete="off"
                  (keyup)="checkPasswordStrengthOne(password.value)" (blur)="setOther()"                  
                  (keydown)="checkEnter($event);" required>
                <button mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint align="start"></mat-hint>
                <mat-hint align="end">{{password.value.length}} / 14</mat-hint>
                <mat-error *ngIf="signUpForm.invalid">Error en el password</mat-error>
                
                <div class="password-indicator" *ngIf="!passwordIsDone && passwordOne.length > 0 && passwordInput && passwordInput.nativeElement === inputActive">
                  <h3>Requerimientos de password:</h3>
                  <ul class="password-indicator-ul">
                    <li *ngFor="let hint of passwordOne">{{ hint }}</li>
                  </ul>
                </div>

              </mat-form-field><br><br>   

              <mat-form-field class="inputWidth">
                <mat-label>{{constants.PLACEHOLDER_REPEAT_PASSWORD}}</mat-label>
                
                <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="passwordRepeat"
                  placeholder="{{constants.PLACEHOLDER_REPEAT_PASSWORD}}" #passwordRepeat maxlength="14"
                  autocomplete="off" (keyup)="checkPasswordStrength(passwordRepeat.value)" (blur)="setOther()"
                  (keydown)="checkKeyCode($event,signUpForm.value);" required>

                <button mat-icon-button matSuffix (click)="changeVisibility1($event)"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                  <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint align="start"></mat-hint>
                <mat-hint align="end">{{passwordRepeat.value.length}} / 14</mat-hint>
                <mat-error *ngIf="signUpForm.get('passwordRepeat').invalid">
                  Error en el password de confirmación
                </mat-error>

                <div class="password-indicator" 
                *ngIf="!passwordRepeatIsDone && passwordRepeatHints.length > 0 && passwordRepeatInput 
                && passwordRepeatInput.nativeElement === inputActive">
                  <h3>Requerimientos de password:</h3>
                  <ul class="password-indicator-ul">
                    <li *ngFor="let hint of passwordRepeatHints">{{ hint }}</li>
                  </ul>
                </div>

              </mat-form-field><br><br>
              <div style="clear:both" class="block">
                <button mat-raised-button color="primary" type="submit"
                  [disabled]="signUpForm.invalid">{{constants.LABEL_SIGNUP}}</button>&nbsp;
                <button mat-raised-button color="accent" type="button"
                  (click)="close()">{{constants.LABEL_CANCEL}}</button><br>
              </div><br><br>
              <span>{{constants.LABEL_HAVE_ACCOUNT}}</span><br>
              <a routerLink="../{{global.ROUTE_LOGIN}}" class="text-anchor">{{constants.LABEL_AUTH}}</a>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
</div>

<div class="container-fluid estilo-login block tres" *ngIf="isSigned">
  <div class="row">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4 text-center">
      <mat-card class="example-card rosado">
        <mat-icon matSuffix color="primary" class="icon-big">check_circle</mat-icon>
        <mat-card-header class="center-block">
          <p class="title_home">Felicitaciones te acabas de registrar!!!</p>
        </mat-card-header><br>
        <mat-card-content style="text-align:justify;padding:20px">
          <p class="content_home text-center">
            Al elegir incursionar en el mundo de las redes de mercadeo, has apostado por una de las industrias con mayor potencial de crecimiento. Desde este momento, te auguramos un viaje lleno de logros y aprendizajes que te llevarán al éxito.<br>
          </p><br>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary" class="size-button"
            routerLink="../{{global.ROUTE_LOGIN}}">Autenticarse</button><br><br>
        </mat-card-actions>
      </mat-card>

    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
</div>
<app-footer></app-footer>