export const global = {

  //PRODUCTION  
  BLOCKCHAIN_ID: '65a5e95adb497479b275e3db',
  USDT_ID:'65a5e987db497479b275e3de', 
  URLBASE_API: 'https://api-force-sales-75bf4126020f.herokuapp.com/api',
  URLBASE_FRONT: 'https://www.forceofsales.com',
  
  //LOCALHOST  
  /*BLOCKCHAIN_ID: '65a5e95adb497479b275e3db',
  USDT_ID:'65a5e987db497479b275e3de', 
  URLBASE_API: 'http://localhost:3001/api',
  URLBASE_FRONT: 'http://localhost:4200',*/

  //VARIABLES DE BLOCKCHAIN Y USDT
  PRICE_USDT: 1,
  IMAGEN_USDT : 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707',  
  SYMBOL_USDT: 'USDT',
  SYMBOL_BLOCKHAIN: 'bsc',  
  BLOCKCHAIN_NAME: 'BNB Smart Chain',
  SMART_CONTRACT_USDT: '0x55d398326f99059ff775485246999027b3197955',  
  
  PAYMENT_FRANCHISE: 'PF',

  FEE_WITHDRAW: 0.03,
  BIR: 'BIR',
  BVIP: 'BVIP',
  BM2X3: 'BM2X3',
  BRY: 'BRY',
  BL: 'BL',
  BESERV: 'BESERV',

  DESCRIPTION_BIR: 'PAGO DE BONO INICIAL',
  DESCRIPTION_BVIP: 'PAGO DE BONO VIP',
  DESCRIPTION_ESERV: 'PAGO DE BONO DE E-SERVICES',
  DESCRIPTION_BL: 'PAGO DE BONO DE LIDERAZGO',
  DESCRIPTION_MATRIZ_2X3: 'PAGO DE BONO MATRIZ',
  DESCRIPTION_MATRIZ_ROYALTY: 'PAGO DE BONO ROYALTY',

  EARN_LEVEL_1_BONUS_INITIAL : 15,
  EARN_LEVEL_2_BONUS_INITIAL : 4,
  EARN_LEVEL_3_BONUS_INITIAL : 2,
  EARN_LEVEL_4_BONUS_INITIAL : 2,
  EARN_LEVEL_5_BONUS_INITIAL : 2,

  POINTS_LEVEL_1_BONUS_INITIAL: 10,
  POINTS_LEVEL_2_BONUS_INITIAL: 5,
  POINTS_LEVEL_3_BONUS_INITIAL: 5,
  POINTS_LEVEL_4_BONUS_INITIAL: 5,
  POINTS_LEVEL_5_BONUS_INITIAL: 5,
  
  //RUTAS
  ROUTE_LOGIN: 'ingresar',
  ROUTE_SIGNUP: 'registrate',
  ROUTE_HOME: 'ingresar',
  ROUTE_SIGNUPSUCCESS: 'registrado_exitosamente',
  ROUTE_DASHBOARD: 'panel',
  ROUTE_PASSWORDRECOVERY: 'recuperar_password',
  ROUTE_RECOVERYSUCCESS: 'enviado_exitosamente',
  ROUTE_ACTIVATE_USER_SUCCESS: 'activate_user',
  ROUTE_PANEL_HOME: 'panel/home',
  ROUTE_COMPANY_PROFILE: 'perfil',
  ROUTE_TRANSACTIONS: 'hacer_pagos', 
  ROUTE_WALLET: 'wallet',
  ROUTE_PAYMENTS: 'payments',
  ROUTE_SECURITY: 'seguridad',
  ROUTE_HOW_IT_WORK: 'como_funciona',
  ROUTE_CONTACT: 'contact',
  ROUTE_TERMS: 'terms',
  ROUTE_PRIVACY: 'privacity_politics',
  ROUTE_BUY: 'comprar',
  LANGUAGE_ES: 'ES',
  LANGUAGE_EN: 'EN',
  ROLE_USER: 'USER_ROLE',
  ROLE_ADMIN: 'ADMIN_ROLE',
  BLOCKCHAIN_ETHEREUM: 'eth',
  BLOCKCHAIN_BINANCE: 'bsc',
  BLOCKCHAIN_TRON: 'tron',
  ROUTE_GATEWAY: 'gateway',
  ROUTE_PAYMENT: 'payment',
  ROUTE_CHECK_TRANSACTION: 'check_transaction',
  ROUTE_INVESTMENT:'investment',
  ROUTE_NFTS: 'nft',
  ROUTE_RECLAMOS: 'reclamos',
  ROUTE_UNIVERSAL_BONUS: 'bono_matriz',
  ROUTE_VIP_BONUS: 'bono_vip',
  ROUTE_INITIAL_BONUS: 'ganancias_por_referido',
  ROUTE_ROYALTY_BONUS: 'bono_royalty',
  ROUTE_MATRIZ_BONUS: 'bono_liderazgo',
  ROUTE_ECOMMERCE_BONUS: 'bono_eservices',
  ROUTE_WITHDRAWALS: 'retiros',
  ROUTE_TOOLS: 'academia',
  ROUTE_ECOMMERCE: 'mi_tienda',
  ROUTE_TEST_USERS: 'test_users',
  ROUTE_COPY_TRADING: 'copy_trading',
  ROUTE_BULL_RUN: 'bull_run',
  ROUTE_CREAR_ARTICULO:'crear_articulo',
  ROUTE_LIST_ARTICULOS:'lista_articulos',
  ROUTE_ARTICLE:'articulo',
  ROUTE_CART_RESUME:'resumen_carrito',
  ROUTE_STORE:'tienda',  
  ROUTE_VENTAS:'ventas',
  ROUTE_PEDIDOS:'pedido',
  ROUTE_ADMIN_PAGOS:'admin_pagos',
  ROUTE_ADMIN_RETIROS:'admin_retiros',
  ROUTE_ADMIN_FRANQUICIAS:'admin_franquicias',
  ROUTE_VIEW_FRANQUICIA:'ver_franquicia',
  ROUTE_DIRECT_SALE: 'venta_directa',
  ROUTE_BONUS_LEADERSHIP: 'bono_liderazgo',
  ROUTE_PACKAGES: 'paquetes',

  //TIPO DE TIENDA 
  TYPE_CATEGORY_ECOMMERCE : 'ec',
  TYPE_CATEGORY_ELEARNING : 'el',
  TYPE_CATEGORY_ENTRETENIMIENTO : 'ed',
  TYPE_CATEGORY_ETRAVEL : 'et',

  //STATUS CART
  STATUS_PAYMENT_PENDENT: 1,
  STATUS_ANULATE_CART: 2,
  STATUS_TIMEOUT_PAYMENT: 3,
  STATUS_CONFIRMED_PAYMENT: 4,
  STATUS_INCOMPLETE_PAYMENT: 5,  
  STATUS_SENT_TO_COURIER: 6,
  STATUS_RECEIVED_SHIPMENT: 7,  

  //STATUS
  STATUS_TRANSACTION_PENDENT: 1,  
  STATUS_TRANSACTION_CANCEL: 2,
  STATUS_TRANSACTION_TIMEOUT: 3,
  STATUS_TRANSACTION_SUCESSFULL: 4,
  STATUS_TRANSACTION_INCOMPLETE: 5,
  STATUS_TRANSACTION_ERROR:6,
  STATUS_TRANSACTION_PAYMENT_SUCCES:7,  
  PASSSWORD_ENCRYPT_SESSION: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6tQUITO',
  
  MEMBRESIA: 'MEMBRESIA',
  EJECUTIVO: 'EJECUTIVO',
  TOP: 'TOP',
  ROYALTY: 'ROYALTY',

  PRICE_TOKEN_MONEY: 1,
};

