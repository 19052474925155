<app-header></app-header>
  <main class="main_content">    
    <div class="row pdlpdr0"> 
      <div class="col-lg-12">
        <mat-sidenav-container >
          <mat-sidenav #sidenav class="menu">
            <mat-list>
              <mat-list-item *ngFor="let link of MENU_USER" >
                <button mat-menu-item routerLink="{{link.route}}" *ngIf="!link.child && link.route!='presentacion'" (click)="sidenav.toggle()">
                  <mat-icon>{{link.icon}}</mat-icon>
                  &nbsp;<span class="option_menu">{{link.menu}}</span>
                </button>

                <button mat-menu-item *ngIf="!link.child && link.route=='presentacion'" (click)="sidenav.toggle();navigate();">
                  <mat-icon>{{link.icon}}</mat-icon>
                  &nbsp;<span class="option_menu">{{link.menu}}</span>
                </button>

                <button mat-menu-item [matMenuTriggerFor]="service_menu" *ngIf="link.child">
                  <mat-icon>{{link.icon}}</mat-icon>
                  &nbsp;<span  class="option_menu">{{link.menu}}</span>                  
                  &nbsp;&nbsp;
                  <mat-icon  class="menu-button mat-icon material-icons ng-star-inserted">expand_more</mat-icon>
                </button>
                <mat-menu #service_menu="matMenu">
                    <ng-container *ngFor="let link2 of  link.child">
                      <button mat-menu-item routerLink="{{link2.route}}" (click)="sidenav.toggle()">
                        <mat-icon>{{link2.icon}}</mat-icon>
                        &nbsp;<span  class="option_menu">{{link2.menu}}</span>
                      </button>
                    </ng-container>
                </mat-menu>
              </mat-list-item>
             </mat-list>
          </mat-sidenav>
          <mat-sidenav-content>
            <div class="row no-scrollx">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-1 text-left mr">
                <button mat-button (click)="sidenav.toggle()" class="min-width0 mat-blue"><mat-icon class="marron">menu</mat-icon></button>
              </div>
              <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11 pdlpdr0">
                <router-outlet></router-outlet>
              </div>
          </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
    </div>  
</main>
<app-footer></app-footer>