import { Component, OnInit } from '@angular/core';
import { CommonsService} from 'src/app/services/commons/commons.service';
import { global } from 'src/app/constants/global';
import { Router } from '@angular/router';
import { AuthLoginService} from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-recoverysuccess',
  templateUrl: './recoverysuccess.component.html',
  styleUrls: ['./recoverysuccess.component.css'],
  providers: [CommonsService, AuthLoginService]
})
export class RecoverysuccessComponent implements OnInit {
  public constants: any;
  public global: any;
  constructor(
    private readonly commonsService: CommonsService,
    public router: Router,
    private readonly authLoginService: AuthLoginService,
    private toastr: ToastrService) {
  }


  ngOnInit(): void {
    this.global = global;
    this.constants = this.commonsService.getConstants();
    if (this.authLoginService.isLoggedIn()) {
      this.toastr.info("Ya te encuentras autenticado en el sistema, se te redireccionó al panel de usuario");
      this.router.navigate([global.ROUTE_DASHBOARD]);
    }
  }

}
