<app-header></app-header>
<div class="container-fluid estilo-login siete" *ngIf="!isAuthenticated">
  <div class="row">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4">
      <div class="form-container" style="text-align: center">
        <mat-card class="example-card rosado">
          <mat-card-header class="center-block">
            <mat-card-title class="pd20">{{constants.LABEL_AUTH}}</mat-card-title>
          </mat-card-header><br>
          <mat-card-content style="text-align:center">
            <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value)">
              <mat-form-field class="inputWidth">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="Email address" autocomplete="off" required>
                <mat-icon matSuffix color>email</mat-icon>
                <mat-error *ngIf="loginForm.invalid">{{msgValidateEmail()}}</mat-error>
              </mat-form-field><br>

              <mat-form-field class="inputWidth">
                <mat-label>{{constants.PLACEHOLDER_PASSWORD}}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" 
                placeholder="{{constants.PLACEHOLDER_PASSWORD}}" #password maxlength="14" autocomplete="off"  (keydown)="checkKey($event,loginForm.value);" required>
                <button mat-icon-button matSuffix (click)="changeVisibility($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint align="start"> </mat-hint>
                <mat-hint align="end">{{password.value.length}} / 14</mat-hint>
                <mat-error *ngIf="loginForm.invalid">{{msgValidatePassword()}}</mat-error>
              </mat-form-field><br><br>
              <div style="clear:both" >
                  <button mat-raised-button color="primary"  type="submit" [disabled]="loginForm.invalid" class="width60">{{constants.LABEL_ENTER}}</button><br>                  
              </div><br>
              <div class="row">
                <div class="col-lg-6 text-center"><a routerLink="../{{global.ROUTE_PASSWORDRECOVERY}}" class="text-anchor">{{constants.LABEL_LOSTPASSWORD}}</a></div>
                <div class="col-lg-6 text-center pdt40">
                  <span>{{constants.LABEL_NOT_HAVE_ACCOUNT}}</span><br>
                <a routerLink="../{{global.ROUTE_SIGNUP}}" class="text-anchor">{{constants.LABEL_CREATE_ACCOUNT}}</a>
                </div>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
</div>

<div class="container-fluid estilo-login pd40" *ngIf="isAuthenticated && isActive2fa">
  <div class="row cinco">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4">
      <div class="form-container" style="text-align: center">
        <mat-card class="example-card rosado">
          <mat-card-header class="center-block">
            <mat-card-title class="pd20">Ingresa tu código 2FA</mat-card-title>
          </mat-card-header><br>
          <mat-card-content style="text-align:center">
            <form [formGroup]="codeForm" (ngSubmit)="onLoginCode(codeForm.value)">
              <mat-form-field class="inputWidth">
                <mat-label>Código 2FA </mat-label>
                <input matInput  (keydown)="checkNumber($event);" formControlName="code2fa" placeholder="Código 2FA generador por Google Authenticator" autocomplete="off" maxlength="6" (keydown)="checkKeyCode($event,codeForm.value);">
                <mat-error *ngIf="codeForm.invalid">{{msgValidateCode()}}</mat-error>                
              </mat-form-field><br><br>              
              <div style="clear:both" >
                <button mat-raised-button color="primary" class="size-button" type="submit" [disabled]="codeForm.invalid" class="width60">{{constants.LABEL_SEND}}</button><br>                  
            </div><br>
            <span>{{constants.LABEL_TEXT_CODE}}</span>                                
            <br><br><span (click)="back()" class="text-anchor">{{constants.LABEL_BACK_LOGIN}}</span>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
</div>
<app-footer></app-footer>



