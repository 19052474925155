import { Component, OnInit, Inject, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from 'src/app/models/UserModel';
import { UtilsService } from 'src/app/utils/utils.service';
import { AuthLoginService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { DOCUMENT } from '@angular/common';
import { CommonsService } from 'src/app/services/commons/commons.service';
import { global } from 'src/app/constants/global';
import { SponsoringCompanyModel } from 'src/app/models/SponsoringCompanyModel';
import { MatrizService } from 'src/app/services/matriz/matriz.service';

//TEST
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { Transaction } from 'src/app/models/Transaction';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [UtilsService, AuthLoginService, CommonsService, MatrizService, TransactionService]
})
export class SignupComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  @ViewChild('passwordRepeat') passwordRepeatInput!: ElementRef;
  @ViewChild('password') passwordInput!: ElementRef;
  public constants: any;
  hide = true;
  hide1 = true;
  dialogRef: any;
  public global: any;
  isSigned = false;
  codeRefered: any;
  codeReferedMember: any;
  errorReferred = false;
  dataProfile: SponsoringCompanyModel;
  dataProfile2: SponsoringCompanyModel;
  passwordRepeatHints: string[] = [];
  passwordOne: string[] = [];
  inputActive: any;
  passwordRepeatIsDone = false;
  passwordIsDone = false;
  cumple = 'Cumple con el patrón';
  is_investor = false;
  thereIsCodeMember = false;
  levelOneFilled = false;

 //TEST
  transaction: Transaction;

  public signUpForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.utilsService.emailPattern)]),
    name: new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(4)]),
    coderefered: new FormControl('',),
    codereferedMember: new FormControl('',),
    password: new FormControl('', [
      Validators.required,
      Validators.maxLength(14), // Ajusta la longitud máxima según tus necesidades
      Validators.minLength(8),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*.?&])[A-Za-z\d@$!%*.?&]{8,20}$/)
    ]),
    passwordRepeat: new FormControl('', [
      Validators.required,
      Validators.maxLength(14), // Ajusta la longitud máxima según tus necesidades
      Validators.minLength(8),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*.?&])[A-Za-z\d@$!%*.?&]{8,20}$/)
    ]),
    phonecel: new FormControl('', [Validators.required, Validators.maxLength(16), Validators.minLength(10)])
  });

  constructor(
    private readonly commonsService: CommonsService,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service,
    private readonly matrizService: MatrizService,
    private readonly utilsService: UtilsService,
    private readonly authLoginService: AuthLoginService,
    private readonly signupService: SignupService,
    private readonly transactionService: TransactionService,
    @Inject(DOCUMENT) private document: any,
    public router: Router,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.global = global;
    this.constants = this.commonsService.getConstants();
    const snapshot = this.router.routerState.snapshot.url.split('/');

    if (this.authLoginService.isLoggedIn()) {
      this.toastr.info("Ya te encuentras autenticado en el sistema, se te redireccionó al panel de usuario");
      this.router.navigate([global.ROUTE_DASHBOARD]);
    } else {

      if (snapshot.length > 2) {

        localStorage.setItem('coderefered', snapshot[2]);
        if (snapshot[3] != undefined) {
          localStorage.setItem('referfortheMember', snapshot[3]);
        }
        this.router.navigate([global.ROUTE_SIGNUP]);

      } else {

        const codeRefered = localStorage.getItem('coderefered');
        const codeReferedMember = localStorage.getItem('referfortheMember');
        if (codeRefered != undefined && codeRefered != null && codeRefered.trim() != "") {

          this.codeRefered = codeRefered;
          localStorage.removeItem('coderefered');

          //Busca el codigo del referido a ver si existe
          this.signupService.getUserByCode(this.codeRefered).subscribe(

            async result => {

              this.dataProfile = result;
              this.is_investor = this.dataProfile.is_investor;              
              this.signUpForm.get('coderefered').setValue(this.dataProfile.name + " (" + this.codeRefered.toString().padStart(7, '0') + ")");

              //Si aparte tiene codigo de referido para un miembro lo busca para ver si existe
              if (codeReferedMember) {
                this.thereIsCodeMember = true;
                this.codeReferedMember = codeReferedMember;
                localStorage.removeItem('referfortheMember');

                //Busca el codigo del referido para miembros a ver si existe
                this.signupService.getUserByCode(this.codeReferedMember).subscribe(
                  result => {
                    this.dataProfile2 = result;
                    this.signUpForm.get('codereferedMember').setValue(this.dataProfile2.name + " (" + this.codeReferedMember.toString().padStart(7, '0') + ")");
                    //this.getMatrizUser(this.dataProfile2._id);
                  },
                  error => {
                    this.errorReferred = true;
                  },
                  () => {

                  });
              }/* else {
                this.getMatrizUser(this.dataProfile._id);
              }*/
            },
            error => {
              this.errorReferred = true;
            },
            () => {

            });
        } else {
          //Quiere registrarse sin tener un referido
          this.errorReferred = true;
        }
      }
    }
    this.subscription = this.recaptchaV3Service.onExecute
      .subscribe((data: OnExecuteData) => {
      });
    this.document.body.classList.add('recaptcha');


    //this.fillTestForm();
  }

  //Evita que el usuario registre mas de un usuario de primer nivel en su matriz
  //Para eso debe darle apoyo a sus referidos o el esperar llenar otra matriz   
  /*getMatrizUser(user: any) {

    this.matrizService.getMatrizBronceSignup(user, false).subscribe(
      result => {
        const result_matriz = result.matriz;
        if (result_matriz.motherA != undefined && result_matriz.motherB != undefined) {
          this.levelOneFilled = true;
        }
      },
      error => {
        this.toastr.error("Ocurrió un error al consultar los datos de la matriz bronce, por favor inténtalo mas tarde");
      },
      () => {

      }
    );
  }*/

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.document.body.classList.remove('recaptcha');
  }

  checkKeyCode(e: KeyboardEvent, model: UserModel) {
    if (e.key === 'Enter') {
      this.onSave(model);
      return false;
    }
  }
  checkEnter(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      return false;
    }
  }


  checkPasswordStrength(value: string) {
    this.inputActive = document.activeElement;
    this.passwordRepeatHints = this.utilsService.validatePasswordStrength(value);
    if (this.passwordRepeatHints.includes(this.cumple)) {
      this.passwordRepeatIsDone = true;
    } else {
      this.passwordRepeatIsDone = false;
    }
  }

  setOther() {
    this.inputActive = document.activeElement;
  }

  checkPasswordStrengthOne(value: string) {
    this.inputActive = document.activeElement;
    this.passwordOne = this.utilsService.validatePasswordStrength(value);
    if (this.passwordOne.includes(this.cumple)) {
      this.passwordIsDone = true;
    } else {
      this.passwordIsDone = false;
    }
  }
  
  onSave(data: UserModel) {
    if (!this.signUpForm.invalid) {
      if (data.password !== data.passwordRepeat) {
        this.signUpForm.get('passwordRepeat').setErrors({ nomatch: true });
        this.toastr.warning(this.constants.MSG_ERROR_PASSWORD_NOMATCH);
      } else {
        this.utilsService.loading('Registrando usuario');
        this.recaptchaV3Service.execute('importantAction')
          .subscribe((token) => {
            const lang = this.commonsService.getLang();
            if (this.codeReferedMember == undefined) {
              this.codeReferedMember = this.codeRefered;
            }
            const user = {
              password: data.password, email: data.email, name: data.name, phonecel: data.phonecel,
              referral_code_member: this.codeReferedMember, referral_code: this.codeRefered,
              subject: this.constants.SUBJECT_SIGNUP_SUCCESSFULL, captcha_token: token, lang
            };
            this.signupService.signup(user).subscribe(
              result => {
                
              },
              error => {
                this.utilsService.closeLoading();
                if (error.error !== undefined && error.error.err !== undefined) {
                  if (error.error.err.errors !== undefined &&
                    error.error.err.errors.email !== undefined
                    && error.error.err.errors.email.kind === 'unique') {
                    this.signUpForm.get('email').setErrors({ repeat: true });
                    this.toastr.warning(this.constants.MSG_ERROR_REPEAT_EMAIL);
                  }
                  if (error.error.err.errorcaptcha !== undefined) {
                    this.toastr.error(this.constants.MSG_ERROR_CAPTCHA);
                  }
                }
              },
              () => {
                this.utilsService.closeLoading();
                this.toastr.info(this.constants.MSG_SUCCESSFULL_SIGNUP);
                this.signUpForm.reset();
                this.isSigned = true;
              });
          });
      }
    }
  }

  changeVisibility(event: any) {
    this.hide = !this.hide;
    return false;
  }

  changeVisibility1(event: any) {
    this.hide1 = !this.hide1;
    return false;
  }

  close() {
    this.signUpForm.reset();
    this.router.navigate([global.ROUTE_HOME]);
  }

  msgValidateRole() {
    return this.signUpForm.get('userType').hasError('required') ? this.constants.LABEL_FIELD_REQUIRED :
      '';
  }

  msgValidateEmail() {
    return this.signUpForm.get('email').hasError('pattern') ? this.constants.LABEL_INVALID_EMAIL :
      this.signUpForm.get('email').hasError('required') ? this.constants.LABEL_FIELD_REQUIRED :
        '';
  }

  msgValidateName() {
    return this.signUpForm.get('name').hasError('maxlength') ? this.constants.LABEL_MAX_LENGTH_30 :
      this.signUpForm.get('name').hasError('minlength') ? this.constants.LABEL_MIN_LENGTH_4 :
        this.signUpForm.get('name').hasError('required') ? this.constants.LABEL_FIELD_REQUIRED :
          '';
  }

  msgValidateCelPhone() {
    return this.signUpForm.get('phonecel').hasError('maxlength') ? this.constants.LABEL_MAX_LENGTH_16 :
      this.signUpForm.get('phonecel').hasError('minlength') ? this.constants.LABEL_MIN_LENGTH_10 :
        this.signUpForm.get('phonecel').hasError('required') ? this.constants.LABEL_FIELD_REQUIRED :
          '';
  }

  checkNumber(e: KeyboardEvent) {
    return this.utilsService.checkNumber(e);
  }

  /*
  //TEST
  onSave(data: UserModel) {
    if (!this.signUpForm.invalid) {
      if (data.password !== data.passwordRepeat) {
        this.signUpForm.get('passwordRepeat').setErrors({ nomatch: true });
        this.toastr.warning(this.constants.MSG_ERROR_PASSWORD_NOMATCH);
      } else {
        this.utilsService.loading('Registrando usuario');
        this.recaptchaV3Service.execute('importantAction')
          .subscribe((token) => {
            const lang = this.commonsService.getLang();
            if (this.codeReferedMember == undefined) {
              this.codeReferedMember = this.codeRefered;
            }
            const user = {
              password: data.password, email: data.email, name: data.name, phonecel: data.phonecel,
              referral_code_member: this.codeReferedMember, referral_code: this.codeRefered,
              subject: this.constants.SUBJECT_SIGNUP_SUCCESSFULL, captcha_token: token, lang
            };
            this.signupService.signupTest(user).subscribe(
              result => {
                const user  = result.usuarioDB;
                //creamos transaccion y simulamos pago
                this.createTransaction(user._id);
              },
              error => {
                this.utilsService.closeLoading();
                if (error.error !== undefined && error.error.err !== undefined) {
                  if (error.error.err.errors !== undefined &&
                    error.error.err.errors.email !== undefined
                    && error.error.err.errors.email.kind === 'unique') {
                    this.signUpForm.get('email').setErrors({ repeat: true });
                    this.toastr.warning(this.constants.MSG_ERROR_REPEAT_EMAIL);
                  }
                  if (error.error.err.errorcaptcha !== undefined) {
                    this.toastr.error(this.constants.MSG_ERROR_CAPTCHA);
                  }
                }
              },
              () => {
                this.utilsService.closeLoading();
                this.toastr.info(this.constants.MSG_SUCCESSFULL_SIGNUP);
                this.signUpForm.reset();
                this.isSigned = true;
              });
          });
      }
    }
  }

  //TEST
  createTransaction(userId) {

    const lang = this.commonsService.getLang();

    const data = {
      usuarioid: userId,
      amount: 50,
      cryptoToSend: 50,
      tokensToReceive: Math.round(50/1),
      cryptoSelected: this.global.USDT_ID,
      blockchainSelected: this.global.BLOCKCHAIN_ID,
      priceCryptoSelected: this.global.PRICE_USDT,
      symbol: this.global.SYMBOL_USDT,
      blockchainSymbol: this.global.SYMBOL_BLOCKHAIN,
      smartcontract: this.global.SMART_CONTRACT_USDT,
      subject: this.constants.LABEL_TRANSACTION,
      description: "PAGO DE PAQUETE DE TOKENS",
      paymentType: this.global.PAYMENT_FRANCHISE,
      blockchainName: this.global.BLOCKCHAIN_NAME,
      lang
    };

    this.transactionService.generateTransactionTest(data).subscribe(
      result => {

        this.transaction = result;        
        this.simularPago(this.transaction);
      },
      error => {        
        this.toastr.error("Ocurrió un error al tratar de generar una transacción de pago de la franquicia, por favor inténtalo mas tarde");
      },
      () => {
        
      }
    );
  }

    //TEST
    simularPago(transaction) {
      
      const lang = this.commonsService.getLang();
      const transactionId = transaction.transactionId;  
      this.transactionService.simulaPagoTest(transactionId, lang).subscribe(
        result => {
          this.checkTransaction();
        },
        error => {
          this.authLoginService.verifySessionWS(error);
        },
        () => {
        }
      );
    }
    
    //TEST
    checkTransaction() {

      const lang = this.commonsService.getLang();
      const data = { transactionId: this.transaction.transactionId, lang };
  
      this.transactionService.checkTransaction(data).subscribe(
        result => {
          
          this.processResult(result);
        },
        error => {
          this.authLoginService.verifySessionWS(error);
        },
        () => {          
        }
      );
    }

    processResult(result: Transaction) {
      if (result.complete !== undefined) {
        this.toastr.success(this.constants.MSG_SUCCESSFUL_TRANSACTION);     
      } 
    }
  
  //Solo para efectos de pruebas
  fillTestForm() {
    this.signUpForm.get('phonecel').setValue("0978952820");
    this.signUpForm.get('password').setValue("Quito23.");
    this.signUpForm.get('passwordRepeat').setValue("Quito23.");

    const nombres = ['Juan', 'Maria', 'Pedro', 'Ana', 'Luis', 'Laura', 'Carlos', 'Sofia', 'Christian', 'Ivan', 'Alberto'];
    const apellidos = ['García', 'Martínez', 'Fernández', 'López', 'Pérez', 'González', 'Díaz', 'Rodriguez', 'Bolivar', 'Jimenez', 'Mendoza'];

    const email = Math.floor(Math.random() * 100) + 1;;
  
    const nombreAleatorio = nombres[Math.floor(Math.random() * nombres.length)];
    const apellidoAleatorio = apellidos[Math.floor(Math.random() * apellidos.length)];
    const nombreCompleto = nombreAleatorio + ' ' + apellidoAleatorio;

    this.signUpForm.get('name').setValue(nombreCompleto);
    this.signUpForm.get('email').setValue(nombreAleatorio+email+"@gmail.com");
  }*/

}
