import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthInterceptor } from 'src/app/auth.interceptor';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginatorModule } from 'primeng/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { SignupComponent } from './modules/signup/signup.component';
import { LoadingComponent } from './modules/loading/loading.component';
import { LostpasswordComponent } from './modules/lostpassword/lostpassword.component';
import { RecoverysuccessComponent } from './modules/lostpassword/recoverysuccess/recoverysuccess.component';
import { ActivateuserComponent } from './modules/signup/activateuser/activateuser.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AdminLayoutComponent } from './modules/dashboard/admin-layout/admin-layout.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ValidateSessionComponent } from './modules/validate-session/validate-session.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule } from 'ng-recaptcha';
import { QRCodeModule } from 'angular2-qrcode';
import { TableModule } from 'primeng/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { PagenotfoundComponent } from './modules/pagenotfound/pagenotfound.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatStepperModule } from '@angular/material/stepper';
import { CarouselModule } from 'primeng/carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    LostpasswordComponent,
    RecoverysuccessComponent,
    ActivateuserComponent,
    FooterComponent,
    HeaderComponent,
    AdminLayoutComponent,
    LoadingComponent,
    ValidateSessionComponent,
    PagenotfoundComponent
  ],
  imports: [
    NgbModule,
    PaginatorModule,
    CarouselModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TableModule,
    FormsModule,
    MatTabsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true,
      progressBar: true
    }),    
    MatToolbarModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatNativeDateModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatSidenavModule,
    MatSliderModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    QRCodeModule,
    MatTooltipModule,
    MatBadgeModule,
    MatStepperModule    
  ],
  providers: [
    MatDatepickerModule,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdXzOMpAAAAADW9VI3W96wC0NMh6zN-DWE_xSdZ'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-in' }],

  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }