import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonsService} from 'src/app/services/commons/commons.service';
import {global} from 'src/app/constants/global';
import { LoginModel } from 'src/app/models/Login';
import * as CryptoJS from 'crypto-js';  
 
@Injectable()
export class AuthLoginService  {

  constants: any;
  public email: any;
  private urlWSREST = global.URLBASE_API ;
  private WSRESTLOGIN = this.urlWSREST + '/login';
  private WSRESTROLE = this.urlWSREST + '/getRoleUser';
  private WSRESTPASSWLOST = this.urlWSREST + '/passwordlost';
  private WSRESTACTIVATEUSER = this.urlWSREST + '/activateuser';
  private WSRESTCHANGEPASSWORD = this.urlWSREST + '/changepassword';
  private WSRESTCODE= this.urlWSREST + '/validateCode';
  private URLWSVALIDATECODE2FA = this.urlWSREST + '/validateCode2FA';
  private URLWSGENERATESECRET = this.urlWSREST + '/generateSecret';
   

  PROFILE_ROLE_USER =  [
    {route: global.ROUTE_DASHBOARD},
    {route: global.ROUTE_COMPANY_PROFILE},
    {route: global.ROUTE_WALLET},
    {route: global.ROUTE_TRANSACTIONS},
    {route: global.ROUTE_SECURITY},
    {route: global.ROUTE_TEST_USERS},
    {route: global.ROUTE_INVESTMENT},
    {route: global.ROUTE_RECLAMOS},
    {route: global.ROUTE_UNIVERSAL_BONUS},
    {route: global.ROUTE_VIP_BONUS},
    {route: global.ROUTE_MATRIZ_BONUS},
    {route: global.ROUTE_INITIAL_BONUS},
    {route: global.ROUTE_ECOMMERCE_BONUS},
    {route: global.ROUTE_ROYALTY_BONUS},
    {route: global.ROUTE_WITHDRAWALS},
    {route: global.ROUTE_TOOLS},
    {route: global.ROUTE_ECOMMERCE},
    {route: global.ROUTE_COPY_TRADING},
    {route: global.ROUTE_BULL_RUN},
    {route: global.ROUTE_ARTICLE},
    {route: global.ROUTE_CART_RESUME},
    {route: global.ROUTE_PEDIDOS},
    {route: global.ROUTE_BONUS_LEADERSHIP},
    {route: global.ROUTE_DIRECT_SALE},
    {route: global.ROUTE_PACKAGES}
    
  ];

  PROFILE_ROLE_ADMIN = [
    {route: global.ROUTE_DASHBOARD},
    {route: global.ROUTE_COMPANY_PROFILE},
    {route: global.ROUTE_WALLET},
    {route: global.ROUTE_TRANSACTIONS},
    {route: global.ROUTE_SECURITY},
    {route: global.ROUTE_TEST_USERS},
    {route: global.ROUTE_INVESTMENT},
    {route: global.ROUTE_RECLAMOS},
    {route: global.ROUTE_UNIVERSAL_BONUS},
    {route: global.ROUTE_VIP_BONUS},
    {route: global.ROUTE_MATRIZ_BONUS},
    {route: global.ROUTE_INITIAL_BONUS},
    {route: global.ROUTE_ECOMMERCE_BONUS},
    {route: global.ROUTE_ROYALTY_BONUS},
    {route: global.ROUTE_WITHDRAWALS},
    {route: global.ROUTE_TOOLS},
    {route: global.ROUTE_ECOMMERCE},
    {route: global.ROUTE_COPY_TRADING},
    {route: global.ROUTE_BULL_RUN},
    {route: global.ROUTE_ARTICLE},
    {route: global.ROUTE_CART_RESUME},
    {route: global.ROUTE_PEDIDOS},
    {route: global.ROUTE_CREAR_ARTICULO},
    {route: global.ROUTE_LIST_ARTICULOS},
    {route: global.ROUTE_VENTAS},    
    {route: global.ROUTE_ADMIN_PAGOS},
    {route: global.ROUTE_ADMIN_RETIROS},
    {route: global.ROUTE_ADMIN_FRANQUICIAS},
    {route: global.ROUTE_VIEW_FRANQUICIA},
    {route: global.ROUTE_BONUS_LEADERSHIP},
    {route: global.ROUTE_DIRECT_SALE},
    {route: global.ROUTE_PACKAGES}
  ];

  constructor(
      private commonsService: CommonsService, private http: HttpClient,
      private router: Router, private toastr: ToastrService) {
    this.http = http;
    this.constants = this.commonsService.getConstants();
  }

  public activate(iduser: string) {
    return this.http.post(this.WSRESTACTIVATEUSER, {iduser});
  }


  public login(data: any) {
    return this.http.post<LoginModel>(this.WSRESTLOGIN, data);
  }

  public lostPassword(data: any) {
    return this.http.post(this.WSRESTPASSWLOST, data);
  }

  public changepassword(data: any) {
    return this.http.post(this.WSRESTCHANGEPASSWORD, data);
  }

  public getRole() {
    const token = localStorage.getItem('token');
    return this.http.post(this.WSRESTROLE, {token});
  }

  public getValidateCode(data: any) {
    return this.http.post<any>(this.WSRESTCODE, data);
  }
  

  public setSession(authResult : any) {    
    const expiresAt = moment().add(authResult.expiresToken, 'second');
    localStorage.setItem('token', authResult.token) ;
    localStorage.setItem('user_auth', this.setEncryptDataSession(authResult._id));
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()) );
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('expires_at');
  }

  public closeSession() {
    this.logout();
  }

  public isLoggedIn(): boolean {
      const result = moment().isBefore(this.getExpiration());
      if (!result) {
         this.logout();
      }
      return result;
  }

  public isLoggedOut() {
      return !this.isLoggedIn();
  }

  private getExpiration() {
      const expiration = localStorage.getItem('expires_at');
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
  }

  public verifySession() {
    if (this.isLoggedOut()) {
      this.toastr.error(this.constants.MSG_ERROR_SESSION_INVALID);
      this.router.navigate([global.ROUTE_HOME]);
    }
  }

  validateSession() {
    return new Promise((resolve, reject) => {
      resolve(!this.isLoggedIn());
    });
  }

  verifyRoleAndModule(route: any) {
    return new Promise((resolve, reject) => {
      let datos: {role?: any, ok?: false};
      this.getRole().subscribe (
        result => {
          datos = result;
          if (datos.role === global.ROLE_USER) {
            resolve(this.verifyAcces(route, this.PROFILE_ROLE_USER));
          } else if (datos.role === global.ROLE_ADMIN) {            
            resolve(this.verifyAcces(route, this.PROFILE_ROLE_ADMIN));
          }
        }, error => {
          this.verifySessionWS(error);
        });
      });
  }

 private verifyAcces(route: any, menu: any[]): boolean {
    const data = menu.find(info => info.route === route);
    if (data === undefined) {
      return false;
    } else {
      return true;
    }
  }

  getDataDecrypt(data:any):any{
    return CryptoJS.AES.decrypt(data, global.PASSSWORD_ENCRYPT_SESSION.trim()).toString(CryptoJS.enc.Utf8);
  }
  
  setEncryptDataSession(data:any):any{    
    data = data.toString().padStart(7, '0');
    const info = CryptoJS.AES.encrypt(data, global.PASSSWORD_ENCRYPT_SESSION).toString();        
    return info;
  }

  public generateSecret(){
    const token = localStorage.getItem('token');
    const data = { token};
    return this.http.post<any>(this.URLWSGENERATESECRET, data);
  }

  public validateCode2FA(code: any, secret: any){
    const token = localStorage.getItem('token');
    const data = { token, secret,code};
    return this.http.post<any>(this.URLWSVALIDATECODE2FA, data);
  }


  public verifySessionWS(error: any) {
    if ((error.error !== undefined && error.error.err !== undefined)
      && error.error.err.tokeninvalid !== undefined || error.error.err.usernotfound !== undefined) {
      this.closeSession();
      this.toastr.error(this.constants.MSG_ERROR_SESSION_INVALID);
      this.router.navigate([global.ROUTE_HOME]);     
    }
  }
}
