<app-header></app-header>
<div class="container-fluid estilo-login block cinco">
  <div class="row">
    <div class="col-md-12 col-lg-4"></div>
    <div class="col-md-12 col-lg-4">
      <mat-card class="example-card rosado">
        <mat-icon matSuffix color="primary" class="icon-big">mail_outline</mat-icon>
        <mat-card-header class="center-block">          
          <p class="title_home">{{constants.LABEL_PASSWD_RECOVERED}}</p>
        </mat-card-header>
        <mat-card-content style="text-align:justify;padding:20px">
          <p class="content_home text-center">
            {{constants.LABEL_CONFIRM_SENT2}}<br>
            {{constants.LABEL_CHECK_EMAIL3}} {{constants.LABEL_CHECK_EMAIL4}}
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button color="primary"  class="size-button" routerLink="../{{global.ROUTE_LOGIN}}" >Autenticarse</button><br><br>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4"></div>
  </div>
</div>
<app-footer></app-footer>
