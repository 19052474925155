import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SponsoringCompanyModel } from 'src/app/models/SponsoringCompanyModel';
import { ResultSponsoringCompanyModel } from 'src/app/models/ResultSponsoringCompanyModel';
import { global } from 'src/app/constants/global';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  private URLWSPROFILE = global.URLBASE_API + '/getDataProfile';
  private URLWSPROFILEBYCODEREF = global.URLBASE_API + '/getProfileByCodeReference';
  private URLWSPROFILELEVEL = global.URLBASE_API + '/getDataProfileByLevel';  
  private URLWSPROFILEMEMBERLEVEL = global.URLBASE_API + '/getDataProfileByLevelMembers';  
  
  private URLWSTOTALDIRECTS = global.URLBASE_API + '/getTotalDirects';
  private URLWSALLPROFILELEVEL = global.URLBASE_API + '/getAllDataProfileByLevel';
  private URLWSPROFILELEVELESERVICE = global.URLBASE_API + '/getDataProfileByLevelEservices';
  private URLWSPAYMENTSMATRIZ = global.URLBASE_API + '/getDataPaymentMatriz';
  private URLWSTOTALEARNINGLEVEL = global.URLBASE_API + '/getEarnedByLevel';
  private URLWSTOTALEARNINGLEVELES = global.URLBASE_API + '/getEarnedByLevelEservices';

  private URLWSCALCULATELEADERMATRIZ = global.URLBASE_API + '/getCalculateLeadershipMatriz';  

  private URLWSSAVEPROFILE = global.URLBASE_API + '/saveDataProfile';
  private URLWSAVEWALLET = global.URLBASE_API + '/saveWalletProfile';
  private URLWSTESTUSERS = global.URLBASE_API + '/test_users';
  private URLWSALLPROFILES = global.URLBASE_API + '/getAllProfiles';
  private URLWSPOSITIONUSER = global.URLBASE_API + '/getPositionInMatriz';
  private URLWSAMOUNTMATRIZ = global.URLBASE_API + '/getAmountMatriz';
  private URLWSGETMATRBYNUMBER = global.URLBASE_API + '/getMatrizByNumber';
  private URLWSUPDATEUSER2FA = global.URLBASE_API + '/updateProfile2FA';
  private URLWSUPDATEUSERDESACTIVE2FA = global.URLBASE_API + '/updateProfileDesactive2FA';
  private URLWSSENDOCDEDESACTIVE2FA = global.URLBASE_API + '/sendEmailCodeDesactive2FA';
  private URLWSSAVEIMAGEPROFILE = global.URLBASE_API + '/saveImage';
  private URLWSDELETEIMAGEPROFILE = global.URLBASE_API + '/deleteImage';

  private URLWSGETALLTOTALSALE = global.URLBASE_API + '/getTotalSales';

  
  
  
  constructor(private http: HttpClient, private router: Router) {
    this.http = http;
  }

  public getTotalsSales(){
    const token = localStorage.getItem('token');
    const data = { token};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSGETALLTOTALSALE, data);
  }


  /** Obtiene la data del perfil */
  public getProfileByCodeReference(reference_code:any) {
    const data = {reference_code };
    return this.http.post<any>(this.URLWSPROFILEBYCODEREF, data);
  }

  /** Borra la imagen del perfil */  
  public deleteImageProfile(imageId) {
    const token = localStorage.getItem('token');
    const data = { token, imageId};
    return this.http.post<any>(this.URLWSDELETEIMAGEPROFILE, data);
  }

    /** Guarda la imagen del perfil */
    public saveImageProfile(image, type) {
      const token = localStorage.getItem('token');
      const data = { token, image, type};
      return this.http.post<ResultSponsoringCompanyModel>(this.URLWSSAVEIMAGEPROFILE, data);
    }
    

  public sendCodeDesactivateCode2FA(){
    const token = localStorage.getItem('token');
    const data = { token};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSSENDOCDEDESACTIVE2FA, data);
  }

  public desactivateCode2FA(code: any){
    const token = localStorage.getItem('token');
    const data = { token, code_confirm: code};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSUPDATEUSERDESACTIVE2FA, data);
  }
  
  public saveCode2FA(secret: any){
    const token = localStorage.getItem('token');
    const data = { token, secret};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSUPDATEUSER2FA, data);
  }

   /** Obtiene la matriz solicitada */
   public getCalculateLeaderMatriz(usuarioId?: any){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSCALCULATELEADERMATRIZ, data);
  }

  /** Obtiene la matriz solicitada */
  public getMatrizByNumber(number:number){
    const token = localStorage.getItem('token');
    const data = { token, matriz_number: number};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSGETMATRBYNUMBER, data);
  }

  /** Obtiene la cantidad de matrices */
  public getAmountMatriz(){
    const token = localStorage.getItem('token');
    const data = { token};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSAMOUNTMATRIZ, data);
  }
  
  
  /** Obtiene todos los perfiles de los usuarios */
  public getPositionInMatriz(usuarioId?:any){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSPOSITIONUSER, data);
  }

  /** Obtiene todos los perfiles de los usuarios */
  public getAllDataProfiles(page: number){
    const token = localStorage.getItem('token');
    const data = { token, page};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSALLPROFILES, data);
  }

    /** Obtiene los perfiles de los usuarios que pagaron su franquicia */
    public createUsersTEST(cant,code){
      const token = localStorage.getItem('token');
      const data = { token, cant, referral_code: code};
      return this.http.post<ResultSponsoringCompanyModel>(this.URLWSTESTUSERS, data);
    }

  /** Obtiene las ganancias totales por niveles  */
  public getTotalsEarnedBonusInitial(user:any,){
    const token = localStorage.getItem('token');
    const data = { token, user_id: user};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSTOTALEARNINGLEVEL, data);
  }

  public getTotalsEarnedBonusEservices(user:any,type_payment:any){
    const token = localStorage.getItem('token');
    const data = { token, user_id: user, type_payment};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSTOTALEARNINGLEVELES, data);
  }

  /** Obtiene los perfiles de los usuarios que pagaron su franquicia */
  public getPaymentMatriz(){
    const token = localStorage.getItem('token');
    const data = { token};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSPAYMENTSMATRIZ, data);
  }

  /** Obtiene los perfiles por nivel  */
  public getProfileLevels(usuarioId: any, level: number, from: number, to: number){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId, level, from, to};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSPROFILELEVEL, data);
  }


  public getProfileMembersLevels(usuarioId: any, level: number, from: number, to: number){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId, level, from, to};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSPROFILEMEMBERLEVEL, data);
  }

  /** Obtiene los perfiles por niveles pero totalizando cuantos tiene en el nivel 1 cada referido */
  public getAllDataProfileByLevel(usuarioId: any, level: number, page: number, type_payment: any){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId, level, page,type_payment};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSALLPROFILELEVEL, data);
  }

  /** Obtiene los perfiles por niveles pero totalizando cuantos tiene en el nivel 1 cada referido */
  public getDataProfileByLevelEservice(usuarioId: any, level: number, page: number){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId, level, page};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSPROFILELEVELESERVICE, data);
  }

  

  /** Obtiene el total de referidos directos  */
  public getTotalDirectos(usuarioId: any, level: number){
    const token = localStorage.getItem('token');
    const data = { token, usuarioId, level};
    return this.http.post<ResultSponsoringCompanyModel>(this.URLWSTOTALDIRECTS, data);
  }


  /** Obtiene la data del perfil */
  public getProfile(usuarioId?: any) {
    const token = localStorage.getItem('token');
    const data = { token, usuarioId };
    return this.http.post(this.URLWSPROFILE, data);
  }

  /** Guarda la data del perfil */
  public saveProfile(datos: SponsoringCompanyModel) {
    const token = localStorage.getItem('token');
    const data = { token, datos };
    return this.http.post(this.URLWSSAVEPROFILE, data);
  }

  /** Guarda la imagen de perfil */
  public saveWallet(wallet, code) {
    const token = localStorage.getItem('token');
    const data = { token, wallet, code };
    return this.http.post(this.URLWSAVEWALLET, data);
  }
}

