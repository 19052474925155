import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {global} from 'src/app/constants/global';
import { Router } from '@angular/router';
import {constantses} from 'src/app/constants/constants.es';


@Injectable({
  providedIn: 'root'
})
export class CommonsService {

  
  constructor(private http: HttpClient, private router: Router) {
    this.http = http;
  }
  public getConstants(): any {
    /*if (localStorage.getItem('lang') !== undefined
    && localStorage.getItem('lang') === 'es') {      
      return constantses;
    } else {
      return constantsen;
    }*/
    return constantses;
  }
  public getLang(): any {
    return global.LANGUAGE_ES;
    /*if (localStorage.getItem('lang') !== undefined
    && localStorage.getItem('lang') === 'es') {
      return global.LANGUAGE_ES;
    } else {
      return global.LANGUAGE_EN;
    }*/
  }


}

