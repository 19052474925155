import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { global } from 'src/app/constants/global';
import { Router } from '@angular/router';
import { ResultMatriz } from 'src/app/models/ResultMatriz';

@Injectable({
  providedIn: 'root'
})
export class MatrizService {

  private urlWSGETMATRIZBRONCE = global.URLBASE_API + '/matriz/getMatrizBronce';
  private urlWSGETMATRIZBRONCESU = global.URLBASE_API + '/matriz/getMatrizBronceSignup';
  private urlWSGETMATRIZROYALTY = global.URLBASE_API + '/matriz/getRoyalty';
  

  constructor(private http: HttpClient, private router: Router) {
    this.http = http;
  }

    /** Obtiene la matriz bronce del usuario */
    getMatrizBronce(userMatrizId?: any, closed?:boolean) {
      const token = localStorage.getItem('token');
      const data = { token, userMatrizId, closed};
      return this.http.post<ResultMatriz>(this.urlWSGETMATRIZBRONCE, data);
    }

    getMatrizBronceSignup(userMatrizId?: any, closed?:boolean) {
      const data = {userMatrizId, closed};
      return this.http.post<ResultMatriz>(this.urlWSGETMATRIZBRONCESU, data);
    }    

    /** Obtiene la matriz bronce del usuario */
    getMatrizRoyalty() {
      const token = localStorage.getItem('token');
      const data = { token};
      return this.http.post<ResultMatriz>(this.urlWSGETMATRIZROYALTY, data);
    }

}
