import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { global} from 'src/app/constants/global';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SignupService {

  private urlWSREST = global.URLBASE_API + '/signup';
  private urlWSRESTTEST = global.URLBASE_API + '/signupTest';
  private URLWSCHECKREFERRALCODE = global.URLBASE_API + '/getProfileByCode';
  
  constructor(private http: HttpClient, private router: Router) {
    this.http = http;
  }

  public getUserByCode(code: any) {
    const data = {code};
    return this.http.post(this.URLWSCHECKREFERRALCODE, data);
  }

  public signup(data: any) {
    return this.http.post(this.urlWSREST, data);
  }  

  
  public signupTest(data: any) {
    return this.http.post<any>(this.urlWSRESTTEST, data);
  }  

}
