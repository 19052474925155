import { OnInit } from '@angular/core';
import {LoadingComponent} from 'src/app/modules/loading/loading.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

export class UtilsService implements OnInit {

  dialogRef: any;
  public email: string;

  public emailPattern: any =
  /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;

  public web = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public facebookReg = '^(https?://)?((www.)?facebook.com)/.+$';
  public instagramReg = '^(https?://)?((www.)?instagram.com)/.+$';
  public twitterReg = '^(https?://)?((www.)?twitter.com)/.+$';
  public youtubeReg = '^(https?://)?((www.)?youtube.com|youtu.?be)/.+$';

  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService) {
    
  }

  ngOnInit(): void {

  }

  copyToClipboard(item: any, msg: any) {   
    if (navigator.clipboard) {
      navigator.clipboard.writeText(item)
        .then(() => {
          this.toastr.success(msg);
        })
        .catch((error) => {
          console.error('Error copiando:', error);
          // Manejar el error aquí
        });
    } else {
      // Fallback para navegadores que no admiten la API de clipboard
      const el = document.createElement('textarea');
      el.value = item;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.toastr.success(msg);
    }
  }
  

  capitalizeFirstLetter(value: string): string {
    if (!value || value.length === 0) {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);    
  }

  validatePasswordStrength(value: string): any[] {    
    let hints = [];
  
    if (!value) return hints;

    if (value.length < 8) {
      hints.push(' Al menos 8 caracteres');
    }
    if (!/[a-z]/.test(value)) {
      hints.push(' Al menos una letra minúscula');
    }
    if (!/[A-Z]/.test(value)) {
      hints.push(' Al menos una letra mayúscula');
    }
    if (!/\d/.test(value)) {
      hints.push(' Al menos un número');
    }
    if (!/[@$!%*.?&]/.test(value)) {
      hints.push(' Al menos un carácter especial @$!%*.?&');
    }    
  
    if (hints.length <= 0) {
      hints.push('Cumple con el patrón');
    }
    return hints;
  }

  checkNumber(e: KeyboardEvent) {
    const keysPermited = [8, 9, 35, 36, 39, 46, 37, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
    if (!keysPermited.includes(e.keyCode)) {
      event.preventDefault();
    }
  }

  checkNumberDecimal(e: KeyboardEvent) {
    const keysPermited = [8, 9, 35, 36, 39, 46, 37, 48, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
    if (!keysPermited.includes(e.keyCode)) {
      event.preventDefault();
    }
  }
  loading(datos :any='') {
    this.dialogRef = this.dialog.open(LoadingComponent, {
      disableClose: true,
      data: { message: datos},
      panelClass: 'mat-modal-without-background'
    });
    this.dialogRef.afterClosed().subscribe();
  }


  closeLoading() {
    if(this.dialogRef){
      this.dialogRef.close();
    }    
  }

    /**
  * Procesando status
  * @param status 
  * @returns 
  */
  processStatus(STATUS: any,  status: number, type_category: string): any {
    const statusData = STATUS.find(data =>
      data.code === status &&
      ((data.type_category && data.type_category === type_category))
    );

    // Si no se encuentra el estado específico para la categoría, buscar el estado genérico
    if (!statusData) {
      const genericStatusData = STATUS.find(data =>
        data.code === status && !data.type_category
      );

      return genericStatusData ? genericStatusData.value : 'Estado no encontrado';
    }

    return statusData.value;
  }

  calculateAmount(amount: number, priceToken: number): number {      
    let valor = amount/priceToken; 
    valor =  Math.round(valor);
    return valor;
  }
  
}
