import { Component, OnInit, Inject} from '@angular/core';
import { CommonsService} from 'src/app/services/commons/commons.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
  providers: [CommonsService]
})
export class LoadingComponent implements OnInit {
  public loading: any;
  public constants: any;

  constructor(private readonly commonsService: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data) {
      
    }

  ngOnInit(): void {
    this.constants = this.commonsService.getConstants();
    if(this.data.message === undefined || this.data.message === '') {
      this.loading = this.constants.LABEL_LOADING_PROCESSING;
    }else{
      this.loading = this.data.message;
    }
  }

}
