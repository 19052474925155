import { Component, OnInit, Inject } from '@angular/core';
import { AuthLoginService } from 'src/app/services/auth/auth.service';
import { CommonsService } from 'src/app/services/commons/commons.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { global } from 'src/app/constants/global';
import { ProfilesService } from 'src/app/services/profiles/profiles.service';
import { SponsoringCompanyModel } from 'src/app/models/SponsoringCompanyModel';
import { UtilsService } from '../utils/utils.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [UtilsService, AuthLoginService, CommonsService, ProfilesService]
})
export class HeaderComponent implements OnInit {
  public constants: any;
  public global: any;
  public showNavBar = false;
  collapsed = true;
  message: string;
  url: any;
  name: string;
  userId: string;
  back_link: any;
  back_link_perfil: any;
  dataProfile: SponsoringCompanyModel;
  is_investor = false;
  code_reference: any;

  constructor(
    private readonly authLoginService: AuthLoginService,
    private readonly commonsService: CommonsService,
    private readonly profilesService: ProfilesService,
    private readonly utilsService: UtilsService,
    public router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.global = global;
    this.constants = this.commonsService.getConstants();
    const snapshot = this.router.routerState.snapshot.url.split('/');
    this.setURLBackLink(snapshot);

    const user_id = localStorage.getItem('user_auth');

    if (this.isLoggedIn()) {

      if (!user_id) {
        this.authLoginService.logout();
        this.toastr.warning("Hubo problemas con su sesión de usuario, ingrese de nuevo");
        this.router.navigate([global.ROUTE_HOME]);
      } else {

        this.userId = this.authLoginService.getDataDecrypt(user_id);

        this.profilesService.getProfile(this.userId).subscribe(
          result => {
            this.dataProfile = result;
            this.is_investor = this.dataProfile.is_investor;
            this.code_reference = this.dataProfile.reference_code;

            const referenceCode = this.code_reference.toString().padStart(7, '0');

            this.name = this.dataProfile.name;
            const namesplit = this.name.split(" ");
            if (namesplit.length > 1) {
              this.name = namesplit[0];
            }


            if (this.is_investor) {
              this.url = this.global.URLBASE_FRONT + "/" + this.global.ROUTE_SIGNUP + "/" + referenceCode;              
            }

          },
          error => {
            this.toastr.warning("Ocurrió un error al consultar los datos del perfil.");
          },
          () => {

          }
        );

      }
    }
  }


  setURLBackLink(snapshot) {

    const condition = (snapshot[1] === this.global.ROUTE_ARTICLE || snapshot[1] === this.global.ROUTE_CART_RESUME
      || snapshot[1] === this.global.ROUTE_STORE || snapshot[1] === this.global.ROUTE_PEDIDOS);

    if (condition && snapshot.length == 2) {
      this.back_link = "../";
      this.back_link_perfil = this.back_link + this.global.ROUTE_DASHBOARD + "/" + this.global.ROUTE_COMPANY_PROFILE;
    } else
      if (condition && snapshot.length == 3) {
        this.back_link = "../../";
        this.back_link_perfil = this.back_link + this.global.ROUTE_DASHBOARD + "/" + this.global.ROUTE_COMPANY_PROFILE;
      } else
        if (condition && snapshot.length == 4) {
          this.back_link = "../../../";
          this.back_link_perfil = this.back_link + this.global.ROUTE_DASHBOARD + "/" + this.global.ROUTE_COMPANY_PROFILE;
        } else
          if (condition && snapshot.length > 4) {
            const long = snapshot.length;
            this.back_link = "";
            for (let i = 0; i < long - 1; i++) {
              this.back_link += "../";
            }
            this.back_link_perfil = this.back_link + this.global.ROUTE_DASHBOARD + "/" + this.global.ROUTE_COMPANY_PROFILE;
          } else {
            this.back_link = "../";
            this.back_link_perfil = this.global.ROUTE_COMPANY_PROFILE;
          }
  }


  //Redirecciona al home dependiendo si estas logueado o no
  goToHome() {
    if (this.isLoggedIn()) {
      this.router.navigate([this.global.ROUTE_DASHBOARD]);
    } else {
      this.router.navigate([this.global.ROUTE_HOME]);
    }
  }

  copyToClipboard(){
    const item =this.url;
    if(!item){
      this.toastr.warning("Lamentamos informarte que no pudimos copiar tu enlace de referido. Por favor, vuelve a iniciar sesión en tu cuenta de usuario e intenta nuevamente.");
    }else{
      this.utilsService.copyToClipboard( this.url, 'Enlace de invitación copiado!');
    }    
  }

  goToPanel() {
    this.router.navigate([this.global.ROUTE_DASHBOARD]);
  }

  closeSession() {
    this.authLoginService.closeSession();
    this.toastr.info(this.constants.MSG_SUCCESSFULL_CLOSE);
    this.router.navigate([global.ROUTE_HOME]);
  }

  isLoggedIn(): boolean {
    return this.authLoginService.isLoggedIn();
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
}