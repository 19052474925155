import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { global } from 'src/app/constants/global';
import { Router } from '@angular/router';
import { Transaction } from 'src/app/models/Transaction';
import { ResultTransactions } from 'src/app/models/ResultTransactions';
import { ResultSponsoringCompanyModel } from 'src/app/models/ResultSponsoringCompanyModel';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  //Servicios de solicitud de pagos
  private urlWSGENREQUESTPAYMENTBU = global.URLBASE_API + '/payments_request/generatePaymentBU';
  private urlWQUERYPAYMENTBU = global.URLBASE_API + '/payments_request/getPaymentBU';
  private urlWSGENREQUESTPAYMENTBIR = global.URLBASE_API + '/payments_request/generatePaymentBIR';
  private urlWSGENREQUESTPAYMENTBM2X3 = global.URLBASE_API + '/payments_request/generatePaymentM2X3';
  private urlWSGENREQUESTPAYMENTBESERV = global.URLBASE_API + '/payments_request/generatePaymentBESERV';


  private urlWSGENREQUESTPAYMENTBL = global.URLBASE_API + '/payments_request/generatePaymentBL';
  private urlWSGENFRANCHISEPAYMENT = global.URLBASE_API + '/payments_request/getFranchisePayment';
  private urlWSGETREQUESTPAYMENT = global.URLBASE_API + '/payments_request/getPaymentRequest';
  private urlWSGETREQUESTPAYMENTNOTLIMIT = global.URLBASE_API + '/payments_request/getPaymentRequestNoLimit';

  //Servicios de transacciones
  private urlWSGENTRANSACTION = global.URLBASE_API + '/transaction/generateTransaction';
  private urlWSGENTRANSACTIONCART = global.URLBASE_API + '/transaction/generateTransactionCart';
  private urlWSCHECKTRANSACTION = global.URLBASE_API + '/transaction/checkTransaction';
  private urlWSGETALLTRANSACTIONS = global.URLBASE_API + '/transaction/getAllTransactions';
  private urlWSANULTRANSACTION = global.URLBASE_API + '/transaction/anulTransaction';
  private urlWSGETLASTTRANSACTION = global.URLBASE_API + '/transaction/getLastTransaction';
  private urlWSGETALLSUCCESS = global.URLBASE_API + '/transaction/getAllSuccesfull';

  private urlWSGETALLINVESTMENT = global.URLBASE_API + '/transaction/getAllInvesment';


  //TEST URL
  private urlWSSIMULAPAGO = global.URLBASE_API + '/transaction/simulaPago';
  private urlWSSIMULAPAGOTEST = global.URLBASE_API + '/transaction/simulaPagoTest';
  private urlWSSIMULAPAGOCARRITO = global.URLBASE_API + '/transaction/simulaPagoCarrito';
  private urlWSGENTRANSACTIONTEST = global.URLBASE_API + '/test/generateTransaction';

  constructor(private http: HttpClient, private router: Router) {
    this.http = http;
  }

  /** Cancela una transaccion */
  public cancelTransaction(data: any) {
    return this.http.post<Transaction>(this.urlWSANULTRANSACTION, data);
  }

  getRequestPayment(usuarioId?: any) {
    const token = localStorage.getItem('token');
    const data = { token, usuarioId };
    return this.http.post<ResultTransactions>(this.urlWSGETREQUESTPAYMENT, data);
  }

  getAllSuccessTransactions(usuarioId?: any) {
    const token = localStorage.getItem('token');
    const data = { token, usuarioId };
    return this.http.post<ResultTransactions>(this.urlWSGETALLSUCCESS, data);
  }

  getRequestPaymentNoLimit() {
    const token = localStorage.getItem('token');
    const data = { token };
    return this.http.post<ResultTransactions>(this.urlWSGETREQUESTPAYMENTNOTLIMIT, data);
  }

  generateRequestPayBL(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<ResultSponsoringCompanyModel>(this.urlWSGENREQUESTPAYMENTBL, data);
  }
  generateRequestPayBESERV(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<ResultSponsoringCompanyModel>(this.urlWSGENREQUESTPAYMENTBESERV, data);
  }
  generateRequestPayBIR(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<ResultSponsoringCompanyModel>(this.urlWSGENREQUESTPAYMENTBIR, data);
  }
  generateRequestPayM2X3(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<ResultSponsoringCompanyModel>(this.urlWSGENREQUESTPAYMENTBM2X3, data);
  }


  /** Obtiene los datos del pago actual de la franquicia */
  getRequestPaymentBU() {
    const token = localStorage.getItem('token');
    const data = { token };
    return this.http.post<ResultTransactions>(this.urlWQUERYPAYMENTBU, data);
  }

  /** Genera una transaccion de blockchain */
  generateTransaction(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<Transaction>(this.urlWSGENTRANSACTION, data);
  }

  /** Genera una transaccion de blockchain */
  generateTransactionCart(datos: any) {
    const data = { data: datos };
    return this.http.post<Transaction>(this.urlWSGENTRANSACTIONCART, data);
  }

  /** Genera una orden de solicitud de pago del Bono Universal */
  generateRequestPayBU(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<Transaction>(this.urlWSGENREQUESTPAYMENTBU, data);
  }

  /** Obtiene los datos del pago actual de la franquicia */
  getFranchisePayment(datos: any) {
    const token = localStorage.getItem('token');
    const data = { token, data: datos };
    return this.http.post<ResultTransactions>(this.urlWSGENFRANCHISEPAYMENT, data);
  }

  /** Chequea la transaccion para revisar si han llegado los fondos */
  checkTransaction(data: any) {
    return this.http.post<Transaction>(this.urlWSCHECKTRANSACTION, data);
  }

  /** Obtiene todas las transacciones de un cliente */
  getAllTransactions(usuarioId?: any, payment_type?: any) {
    const token = localStorage.getItem('token');
    const data = { token, usuarioId, payment_type };
    return this.http.post<ResultTransactions>(this.urlWSGETALLTRANSACTIONS, data);
  }

  /** Obtiene todas las transacciones de un cliente */
  getLastTransaction(usuarioId?: any) {
    const token = localStorage.getItem('token');
    const data = { token, usuarioId };
    return this.http.post<ResultTransactions>(this.urlWSGETLASTTRANSACTION, data);
  }

  getAllInvestment() {
    const token = localStorage.getItem('token');
    const data = { token };
    return this.http.post<ResultTransactions>(this.urlWSGETALLINVESTMENT, data);
  }

  //TEST
  generateTransactionTest(datos: any) {
    const data = { data: datos };
    return this.http.post<Transaction>(this.urlWSGENTRANSACTIONTEST, data);
  }

  //TEST CARRITO
  simularPagoCarrito(transactionId: any, lang: any) {
    const token = localStorage.getItem('token');
    const data = { token, transactionId, lang };
    return this.http.post<Transaction>(this.urlWSSIMULAPAGOCARRITO, data);
  }

  //TEST
  simulaPago(transactionId: any, lang: any) {
    const token = localStorage.getItem('token');
    const data = { token, transactionId, lang };
    return this.http.post<Transaction>(this.urlWSSIMULAPAGO, data);
  }

  //TEST
  simulaPagoTest(transactionId: any, lang: any) {
    const data = { transactionId, lang };
    return this.http.post<Transaction>(this.urlWSSIMULAPAGOTEST, data);
  }

}